import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext'
import { listaRevs, listaMeses } from '../listasPlanoSafra';
// import {calcPS, setZeros} from '../opoio/calcPS';
import '../../css/botoes.css'
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import psHooks from '../../Hooks/psHooks';
import { usePsCalcs } from '../../BME/Unidades/Geral/usePsCalcs';

export default function SubMenuEdit() {
  const {psIsSave, setPsIsSaved, psData, setPsData, allPsData, editingMonth, setEditingMonth} = useContext(PSContext)
  const {data, site, user} = useContext(GlobalStateContext)
  const [selectedMonth, setSelectedMonth] = useState(editingMonth);
  const [selectedOption, setSelectedOption] = useState('opcao1');
  const [selectedRev, setSelectedRev] = useState(listaRevs[0]);
  const [revName, setRevName] = useState('');
  const {newAction} = AnalyticsHooks()
  const psCalcs = usePsCalcs()
  // const {calcPS, setZeros} = psHooks()

  const handleChangeMes = (event) => {
    setSelectedMonth(event.target.value);
    setEditingMonth(event.target.value)
  };

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    let validMonths = []
    listaMeses.map((item) => {
      if (checkPlan(psData, setMonth(item))){
        validMonths.push(item)
      }
    })
    setSelectedMonth(validMonths[0])
    
  }, []);

  async function importarBME() {
    newAction('impBMEpPS', site, user.email || '')
    const updatedData = psData.map((item) => {
      let bmeCod = getBmeCod(item.codigo);
      if (bmeCod !== '') {
        const bmeValue = item.codigo === 'psAlerts' ? 1 : data[bmeCod]?.valor;
        if (bmeCod === 'prodVaporTotal'){
          console.log('prodVaporTotal', bmeValue, bmeCod, item.codigo)
        }
        switch (selectedMonth){
            case 'Janeiro': return { ...item, jan: bmeValue };
            case 'Fevereiro': return { ...item, fev: bmeValue };
            case 'Março': return { ...item, mar: bmeValue };
            case 'Abril': return { ...item, abr: bmeValue };
            case 'Maio': return { ...item, mai: bmeValue };
            case 'Junho': return { ...item, jun: bmeValue };
            case 'Julho': return { ...item, jul: bmeValue };
            case 'Agosto': return { ...item, ago: bmeValue };
            case 'Setembro': return { ...item, set: bmeValue };
            case 'Outubro': return { ...item, out: bmeValue };
            case 'Novembro': return { ...item, nov: bmeValue };
            case 'Dezembro': return { ...item, dez: bmeValue };
        }
        
      }
      return item;
    });
    // let teste = await calcPS(updatedData)
    setPsData(updatedData);
    console.log('PsData', updatedData)
    setPsIsSaved(false)
    // console.log('Ps Teste')
    // console.log(teste)
    
  }

  async function zerarMes(){
    let month = ''
    switch (selectedMonth){
      case 'Janeiro': 
        month = 'jan'
        break
      case 'Fevereiro': 
        month = 'fev'
        break
      case 'Março': 
        month = 'mar'
        break
      case 'Abril':
        month = 'abr'
        break
      case 'Maio':
        month = 'mai'
        break
      case 'Junho':
        month = 'jun'
        break
      case 'Julho':
        month = 'jul'
        break
      case 'Agosto':
        month = 'ago'
        break
      case 'Setembro':
        month = 'set'
        break
      case 'Outubro':
        month = 'out'
        break
      case 'Novembro':
        month = 'nov'
        break
      case 'Dezembro':
        month = 'dez'
        break
  }
  const updatedData = psData.map((item) => {
    const bmeCod = getBmeCod(item.codigo);
    
    if (bmeCod !== '') {
      const bmeValue = item.codigo === 'psAlerts' ? 1 : 0.0;
      switch (selectedMonth){
          case 'Janeiro': return { ...item, jan: bmeValue };
          case 'Fevereiro': return { ...item, fev: bmeValue };
          case 'Março': return { ...item, mar: bmeValue };
          case 'Abril': return { ...item, abr: bmeValue };
          case 'Maio': return { ...item, mai: bmeValue };
          case 'Junho': return { ...item, jun: bmeValue };
          case 'Julho': return { ...item, jul: bmeValue };
          case 'Agosto': return { ...item, ago: bmeValue };
          case 'Setembro': return { ...item, set: bmeValue };
          case 'Outubro': return { ...item, out: bmeValue };
          case 'Novembro': return { ...item, nov: bmeValue };
          case 'Dezembro': return { ...item, dez: bmeValue };
      }
      
    }
    return item;
  });
  // console.log('Zerar Mes', month, updatedData)
  let temp = psCalcs.setZeros(updatedData, month)
  let temp2 = await psCalcs.calcPS(temp)
  setPsData(temp2);
  }


function getBmeCod(psCod){
    switch (psCod){
        case "psAtrPcts": return 'atrCanaPCTS'
        case "psFibraCana": return 'fibraCana'
        case "psAtrDigestor": return 'artCana'
        case "psFibraBagaco": return 'fibraBagaco'
        case "psTaxaMoagem": return 'flowCana'
        case "psMixAcucar": return 'mixAcucar'
        case "psPerdaLavavem": return 'perdaArtLavagemCana'
        case "psPerdaBagaco": return 'perdaArtExtracao'
        case "psPerdaTorta": return 'perdaArtTorta'
        case "psPerdaMultijato": return 'perdaArtMultijatoFabrica'
        case "psPerdaFermentacao": return 'perdaFermDest'
        case "psPerdaResiduaria": return 'perdaArtAguaResiduaria'
        case "psPerdaArtSaidaEvap": return 'perdaArtSaidaEvap'
        case "psPerdaArtSaidaVacuo": return 'perdaArtSaidaVacuo'
        case "psPerdaIndeterminada": return 'perdaArtIndeterminda'
        case "psEficienciaIndustrial": return 'efIndustrial'
        case "psRTC": return 'rtc'
        case "psRitStab": return 'ritSTAB'
        case "psRitArt": return 'ritART'
        case "psRelProdAcArtEnt": return 'relProdAcucarArtEnt'
        case "psRecSJM": return 'recupSJM'
        case "psTaxaEfProdAcucar": return 'flowAcucarTondia'
        case "psProdEfEtanolHid": return 'flowEtanolDia'
        case "psProdEfEtanolAnidro": return 'flowEtanolAnidroDia'
        case "psProdEfEtanolNeutro": return 'flowEtanolNeutroDia'
        case "psProdEfEtanol100": return 'flowVolEtanolProd100Dia'
        case "psProdEfEtanolHidpTq": return 'flowEtanolHidDia'
        case "psRepEfEtanolHid": return 'flowEtanolTanqueToDesidDia'
        case "psRGD": return 'rgd'
        case "psPtGerEnergia": return 'ptGerEnergia'
        case "psPtExportada": return 'expEnergia'
        case "psPtConsumidaInd": return 'consEnergia'
        case "psPtConsumidaAgr": return 'ptConsEnergiaAgricola'
        // case "psPtConsumida": return 'consEnergia'
        case "psEfProdLevedura": return 'flowLeveduraSecaDia'
        case "psEfProdLeveduraProp": return 'flowLevSecaPropriaDia'
        case "psEfProdLeveduraTerc": return 'flowLevSecaTerceirosDia'
        case "psProdEfVapor": return 'prodVaporTotal'
        case "psConsVaporTc": return 'consVaporTC'
        case "psProdEfBagacao": return 'flowBagaco'
        case "psConsEfBagacao": return 'consTotalBagaco'
        case "psProdEfVinhaca": return 'flowVinhaca'
        case "psProdEfTorta": return 'flowTorta'
        case 'psDesvioVinhacapBiogas': return 'desvioVinhacapBiogas'
        case 'psSolVolVinhaca': return 'solVolVinhaca'
        case 'psRendBiometano': return 'rendBiometano'
        case 'psProdHoraCH4': return 'prodHoraCH4'
        case 'psProdDiaCH4': return 'prodDiaCH4'
        case "psAlerts": return 'psAlerts'
        default: return ''
    }
}

  
    return (
      <>
       <div style={{fontSize: '1.6vh', textAlign: 'left', color: 'black', fontWeight: 'bold', alignSelf: 'left', paddingLeft: '2vh', paddingTop: '1vh'}}>{psData[0].revisao !== '13_Realizado' ? 'EDITAR PLANO' : 'EDITAR REALIZADO'}</div>
      <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '2vh'}}>
         
         <div style={{textAlign: 'left', marginRight: '3vh', paddingBottom: '2vh'}}>
        <label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>MÊS:</label>
        <select id="comboBox" value={selectedMonth} onChange={handleChangeMes} style={{color: 'black'}}>
          {listaMeses.map((item) => {
            if (checkPlan(psData, setMonth(item))){
              return (
                <option value={item} key={item}>{item}</option>
            )
            }
          })}
        </select>
      </div>
  
  {psData[0].revisao !== '13_Realizado' &&
    <button className='green-button' style={{alignSelf: 'center'}} onClick={importarBME}>IMPORTAR BME</button>}
  {psData[0].revisao !== '13_Realizado' &&
      <button className='grey-button' style={{alignSelf: 'center', marginLeft: '2vh'}} onClick={zerarMes}>ZERAR MÊS</button>}
  </div>
  
  </>
    )
  
  
  
}

function setMonth(fullMonth){
  let month = fullMonth.substring(0, 3).toLowerCase();
  return month
}

function checkPlan(psData, mes){
  let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  let revisao = psData[0].revisao
  let month = revisao.slice(-3).toLowerCase();
  let index = months.indexOf(month);
  let index1 = months.indexOf(mes);
  if (index1 < index){
    return false
  }else{
    return true
  }
  
}
import { useContext } from "react"
import GlobalStateContext from "../../../components/GlobalStateContext"
import psCalcsUBT from "../usBatatais/Plano Safra/psCalcsUBT"
import psCalcsSEL from "../usSantaElisa/Plano Safra/psCalcsSEL"
import psCalcsSON from "../usSonora/Plano Safra/psCalcsSON"
import psCalcsUMA from "../usMonteAlegre/Plano Safra/psCalcsUMA"
import psCalcsCVS from '../usCevasa/Plano Safra/psCalcsCVS'
import psCalcsUSL from '../usLins/Plano Safra/psCalcsUSL'
import psCalcsTST1 from "../usTeste1/Plano Safra/psCalcsTST1"
import psCalcsJBA from '../usJbAlcoolquimica/Plano Safra/psCalcsJBA'


export function usePsCalcs(){
    const {site} = useContext(GlobalStateContext)
    switch (site){
        case 'usSantaElisa': return psCalcsSEL()
        case 'usBatatais': return psCalcsUBT()
        case 'usSonora': return psCalcsSON()
        case 'usMonteAlegre': return psCalcsUMA()
        case 'usCevasa': return psCalcsCVS()
        case 'usLins': return psCalcsUSL()
        case 'usJbAlcoolquimica': return psCalcsJBA()
        case 'usTeste1': return psCalcsTST1()
        default: 
            return psCalcsUBT()
    }
}
module.exports = {
  getScreenDataUsJba
}
    

function getScreenDataUsJba(area, screen, data){
    if (area === 'resumoProd'){
      return resProd
    } 
    if (area === 'precos'){
      return precos
    } 

    switch (''+area+','+screen){
        case 'extracao,fluxos': return extFlows(data)
        case 'extracao,capacidades': return extCap
        case 'tratAcucar,fluxos': return tratAcFlows
        case 'tratAcucar,aquec': return tratAcAquec(data)
        case 'tratAcucar,regen': return tratAcRegen
        case 'tratAcucar,capacidades': return tratAcCap
        case 'tratEtanol,fluxos': return tratEtFlows
        case 'tratEtanol,aquec': return tratEtEquips(data)
        case 'tratEtanol,capacidades': return tratEtCap
        case 'filtLodo,equipamentos': return filtEquips
        case 'evaporacao,fluxos': return evapFlows
        case 'evaporacao,equipamentos': return evapEquips
        case 'evaporacao,capacidades': return evapCap
        case 'fermentacao,equipamentos': return fermEquips
        case 'fermentacao,fluxos': return fermFlows
        case 'fermentacao,capacidades': return fermCap
        case 'destilaria,premissas': return destPremissas(data)
        case 'destilaria,equipamentos': return destEquips(data)
        case 'flotador,premissas': return flotPrem
        case 'flotador,capacidades': return flotCap
        case 'fabrica,premissas': return fabPrem
        // case 'fabrica,fluxos': return fabFlows
        case 'fabrica,equipamentos': return fabEquips
        case 'fabrica,capacidades': return fabCap
        case 'vapor,premissas': return vapPrem(data)
        case 'vapor,turboger': return vapTGs(data)
        case 'vapor,boilers': return vapBoilers(data)
        case 'vapor,capacidades': return vapCap
        case 'balHidrico,fluxos': return bhPrem
        case 'balHidrico,equipamentos': return bhEquips
        default: return [{}]
    }
}

const resProd = 
  [
    { 
      col: 1,
      sectionName: 'MOAGEM',
      props: ['flowCana', 'flowCanaDia', 'artCana', 'fibraCana', 'embCana', 'fibraEmbeb'],
      ipt: [false,false,false,false,false, false]
    },
    { 
      col: 1,
      sectionName: 'PRODUÇÃO AÇÚCAR',
      props: ['mixAcucar', 'flowAcucar', 'flowAcucarScsdia', 'flowAcucarScsTc', 'flowAcucarTondia', 'recupSJM'],
      ipt: [false,false,false,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'PRODUÇÃO ETANOL',
      props: ['flowVolEtanolProd100Dia',  'flowEtanolHidDia',  'mixEtanolAnidro', 'flowEtanolAnidroDia', 'mixEtanolNeutro', 'flowEtanolNeutroDia', 'mixEtanolDest', 'flowEtanolDestDia', 'efFermentacao', 'efDestilaria', 'rgd'],
      ipt: [false,false,false,false,false, false,false,false,false, false, false]
    },
    { 
      col: 1,
      sectionName: 'EVAPORAÇÃO / FÁBRICA',
      props: ['taxaEvapPreEvapAcucar', 'taxaEvap2EfEvapAcucar', 'taxaMediaEvapUltimosEf', 'ciclosMassaA', 'ciclosMassaB'],
      ipt: [false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'ENERGIA',
      props: ['consEspEnergiaTC', 'consEnergia', 'consEnergiaDia'],
      ipt: [false, false, false]
    },
    { 
      col: 2,
      sectionName: 'LEVEDURA',
      props: ['flowLeveduraSeca', 'flowLeveduraSecaDia', 'levSecaPorLitrosEt'],
      ipt: [false,false,false]
    },
    { 
      col: 2,
      sectionName: 'BAGAÇO',
      props: ['flowBagaco', 'umidBagaco', 'sobraBagaco'],
      ipt: [false,false,false]
    },
    { 
      col: 2,
      sectionName: 'PERFORMANCE',
      props: ['rtc',  'efIndustrial', 'prodUnieqs', 'prodUnieqsTc', 'artProdutos', 'consVaporTC', 'consAguaTC'],
      ipt: [false,false,false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'PERDAS',
      props: ['perdaArtExtracao', 'perdaFermDest', 'perdaArtLavagemCana', 'perdaArtTorta', 'perdaArtMultijatoFabrica', 'perdaArtAguaResiduaria', 'perdaArtIndeterminda', 'perdaTotalArt'],
      ipt: [false,false,true,true,true,true,true,false]
    },

    { 
      col: 2,
      sectionName: 'RECEITA BRUTA',
      props: ['receitaAcucar', 'receitaHidratado', 'receitaAnidro', 'receitaEnergia', 'receitaLevSeca', 'receitaBagaco', 'receitaCH4', 'receitaTotalTC'],
      ipt: [false,false,false,false,false,false, false, false]
    }
  ]

const precos = 
[
  { 
    col: 1,
    sectionName: 'PREÇOS',
    props: ['precoAcucar', 'precoEtanolHid', 'precoEtanolAnidro', 'precoEnergia', 'precoLevSeca', 'precoBagaço', 'precoCH4', 'receitaTotal'],
    ipt: [true,true,true,true,true,true,true,false]
  }
]

function extFlows(data){
   return [
    { 
      col: 1,
      sectionName: 'CANA',
      props: ['flowCana', 'horasEfetivas', 'tfhM1', 'flowCanaDia', 'artCana',  'atrCanaPCTS', 'purezaCana', 'fibraCana', 'arCana', 'tempCana','polCana', 'brixCana'],
      ipt: [true,true, false, false, true,false,true,true,true,true,false,false]
    },
    // { 
    //   col: 1,
    //   sectionName: 'CANA',
    //   props: ['flowCana', 'horasEfetivas', 'flowCanaDia', 'opCalcArt',  'relAtrArt', 'artCana', 'atrCanaPCTS', 'purezaCana', 'fibraCana', 'arCana', 'tempCana','polCana', 'brixCana'],
    //   ipt: [true,true, false,true, true,true, false,true,true,true,true,false,false]
    // },
    { 
      col: 1,
      sectionName: 'EMBEBIÇÃO',
      props: ['flowEmbeb', 'fibraEmbeb', 'embCana', 'tempEmbeb'],
      ipt: [false, true, false, true]
    },
    { 
      col: 2,
      sectionName: 'BAGAÇO',
      props: ['flowBagaco', 'efExtMoenda', 'fibraBagaco', 'umidBagaco', 'brixBagaco', 'artBagaco','polBagaco'],
      ipt: [false,true,true,true,true, false,false]
    },
    { 
      col: 2,
      sectionName: 'CALDO',
      props: ['extCaldo1T', 'caldoPrimToAcucar', 'caldoSecToAcucar','caldoFiltToAcucar'],
      ipt: [true, true, true, true]
    },
    { 
      col: 2,
      sectionName: 'OUTROS',
      props: ['consCondVGLimpPeneira'],
      ipt: [true]
    }
  ]
} 
  

  const extCap = 
  [
    { 
      col: 1,
      sectionName: 'MOENDA',
      props: ['tfhM1', 'tfhM1'],
      ipt: ['min', 'max'],
      desc: ['Moagem Mínima', 'Capacidade Moagem']
    }
    
  ]

// function extEquips(data){ 
//     return [
//     { 
//       col: 1,
//       sectionName: 'AQUECIMENTO',
//       props: [
//         'qtdeOpAqDifusor', 'areaAqDifusor', 'coefTrocaAqDifusor', 'relCaldoEscCana', 'flowCaldoEscaldante', 'tempCaldoEscaldante','tempOutAqDifusor', 'utilAqDifusor',
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2AquecDifusor' : 'consVaporVV1AquecDifusor', 
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2BorbDifusor' : 'consVaporVV1BorbDifusor', 
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2AquecDirDifusor' : 'consVaporVV1AquecDirDifusor', 
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVV2TotalDifusor' : 'consVV1TotalDifusor', 
//     ],
//       ipt: ['combo',true,true,true,false,true,true,false,false,true,false,false]
//     },
//     { 
//       col: 1,
//       sectionName: 'ACIONAMENTO NIVELADOR',
//       props: ['tempVaporVENivelador', 'consEspEnergiaNivelador', 'consEspVaporNivelador', 'consVaporVMNivelador', 'ptNivelador'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 1,
//       sectionName: 'ACIONAMENTO PICADOR',
//       props: ['tempVaporVEPicador', 'consEspEnergiaPicador', 'consEspVaporPicador', 'consVaporVMPicador', 'ptPicador'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 2,
//       sectionName: 'ACIONAMENTO DESFIBRADOR',
//       props: ['tempVaporVEDesfibrador', 'consEspEnergiaDesfibrador', 'consEspVaporDesfibrador', 'consVaporVMDesfibrador', 'ptDesfibrador'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 2,
//       sectionName: 'ACIONAMENTO TERNO DESAGUADOR',
//       props: ['tempVaporVEAcion12T', 'consEspEnergiaAcion12T', 'consEspVaporAcion12T', 'consVaporAcion12T', 'ptAcion12T'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 2,
//       sectionName: 'ACIONAMENTO TERNO SECAGEM',
//       props: ['tempVaporVEAcion34T', 'consEspEnergiaAcion34T', 'consEspVaporAcion34T', 'consVaporAcion34T', 'ptAcion34T'],
//       ipt: [true,true,true,false,false]
//     }
//   ]
// }

const tratAcFlows = 
  [
    { 
      col: 1,
      sectionName: 'DOSAGEM CAL',
      props: ['grausBaume', 'dosagemCalAcucar'],
      ipt: [true,true]
    },
    { 
      col: 1,
      sectionName: 'DOSAGEM POLIMERO',
      props: ['propPreparoPolimero','propDosagemPolAcucar'],
      ipt: [true, true]
    },
    { 
      col: 2,
      sectionName: 'DECANTAÇÃO',
      props: ['lodoCaldoFlashAcucar', 'quedaTempDecantador', 'tempCaldoDecAcucar'],
      ipt: [true,true, false]
    },
    // { 
    //   col: 2,
    //   sectionName: 'DIVISÃO CALDO FILTRADO',
    //   props: ['caldoFiltToAcucar', 'flowCaldoFiltToAcucar', 'flowCaldoFiltToEtanol'],
    //   ipt: [true,false,false]
    // }
  ]

const tratAcRegen = 
     [
      // { 
      //   col: 1,
      //   sectionName: 'REGENERADOR CALDO x MOSTO',
      //   props: ['tempMosto', 'tempMostoReg', 'tempOutCaldoRegCaldoMosto', 'uRegCaldoMosto', 'regCxMareaReq', 'mldtRegCaldoMosto'],
      //   ipt: [false,true,false,true,false,false]
      // },
        { 
          col: 1,
          sectionName: 'REGENERADOR CALDO x VINHAÇA',
          props: ['tempVinhaca', 'tempVinhacaReg', 'tempOutCaldoRegCaldoVinhaca','uRegCaldoVinhaca', 'regCvCTareaReq', 'mldtRegCaldoVinhaca'],
          ipt: [true,true,false,true,false,false]
        },
        { 
          col: 2,
          sectionName: 'REG. CALDO x CONDENSADO VEGETAL GERAL',
          props: ['tempCondVG', 'tempCondReg', 'tempOutCaldoRegCaldoCond', 'uRegCaldoCond', 'regCxCVGCTareaReq', 'mldtRegCaldoCond'],
          ipt: [true,true,false,true,false,false]
        },
        // { 
        //   col: 2,
        //   sectionName: 'REG. CALDO x CONDENSADO VG1',
        //   props: ['opRegCaldoCondVG1', 'tempCondVG1EvapAc', 'tempCondVG1Reg', 'tempOutRegCaldoCondVG1', 'regCxCVG1CTareaTroca', 'uRegCaldoCondVG1', 'regCxCVG1CTareaTrocaTotal', 'regCxCVG1CTareaReq', 'mldtRegCaldoCondVG1'],
        //   ipt: ['combo',true,true,false,true,true,false,false,false]
        // }
      ]

      const tratAcCap = [
        // {col:1, sectionName: "REGENARAÇÃO CALDO x MOSTO",
        // props: ['capRegCaldoxMosto1', 'capRegCaldoxMosto2', 'capRegCaldoxMosto3'],
        // ipt: ['cap2', 'cap2', 'cap2']},
        {col:1, sectionName: "REGENARAÇÃO CALDO x VINHAÇA",
        props: ['capRegCaldoxVinhaca1', 'capRegCaldoxVinhaca2', 'capRegCaldoxVinhaca3'],
        ipt: ['cap2', 'cap2', 'cap2']},
        {col:1, sectionName: "REGENARAÇÃO CALDO x CONDENSADO",
        props: ['capRegCaldoxCondensado1', 'capRegCaldoxCondensado2', 'capRegCaldoxCondensado3'],// 'capRegCaldoxCondensado4'],
        ipt: ['cap2', 'cap2', 'cap2']},
        {col:1, sectionName: "1º AQUECIMENTO VV3",
          props: ['capAqVV3Acucar1', 'capAqVV3Acucar2'],
          ipt: ['cap2', 'cap2']},
        {col:1, sectionName: "AQUECIMENTO VFL",
        props: ['capAqVFLAcucar1', 'capAqVFLAcucar2'],
        ipt: ['cap2', 'cap2']},
        
        {col:1, sectionName: "2º  AQUECIMENTO VV3",
        props: ['capAq2VV3Acucar1', 'capAq2VV3Acucar2'],
        ipt: ['cap2', 'cap2']},
        {col:2, sectionName: "AQUECIMENTO VV2",
        props: ['capAqVV2Acucar1', 'capAqVV2Acucar2', 'capAqVV2Acucar3', 'capAqVV2Acucar4'],
        ipt: ['cap2', 'cap2', 'cap2', 'cap2']},
        {col:2, sectionName: "AQUECIMENTO VV1",
        props: ['capAqVV1Acucar1', 'capAqVV1Acucar2', 'capAqVV1Acucar3', 'capAqVV1Acucar4'],
        ipt: ['cap2', 'cap2', 'cap2', 'cap2']},
        {col:2, sectionName: "AQUECIMENTO VV1 CALDO CLARIFICADO",
          props: ['capAqCCAcucar1', 'capAqCCAcucar2'],
          ipt: ['cap2', 'cap2']},
        {col:2, sectionName: "AQUECIMENTO VE CALDO CLARIFICADO",
            props: ['capAqCCVEAcucar1', 'capAqCCVEAcucar2'],
            ipt: ['cap2', 'cap2']},
      ]

function tratAcAquec(data){  
 return [
  { 
    col: 1,
    sectionName: '1º AQUECIMENTO VV3',
    props: ['tempOutAqVV3Acucar', 'utilAqVV3Acucar', 'consVaporVV3AqAcucar', 'coefTrocaAqVV3Acucar'],
    ipt: [true,false,false,true]
  },
  { 
    col: 1,
    sectionName: 'AQUECIMENTO VFL',
    props: data['opVFLAqDirAcucar'].valor === 0 ? ['opVFLAqDirAcucar','aprovVFLAcucar', 'tempOutAqVFLAcucar', 'utilAqVFLAcucar', 'consVaporVFLAqAcucar', 'coefTrocaAqVFLAcucar'] : 
    ['opVFLAqDirAcucar','aprovVFLAcucar', 'tempOutAqVFLAcucar',  'consVaporVFLAqAcucar'],
    ipt: data['opVFLAqDirAcucar'].valor === 0 ? ['switch',true,false,false,false,true] : ['switch',true,false,false]
  },
  
  { 
    col: 1,
    sectionName: '2º AQUECIMENTO VV3',
    props: ['tempOutAq2VV3Acucar', 'utilAq2VV3Acucar', 'consVaporVV3Aq2Acucar', 'coefTrocaAq2VV3Acucar'],
    ipt: [true,false,false,true]
  },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO VV2',
      props: ['tempOutAqVV2Acucar', 'utilAqVV2Acucar', 'consVaporVV2AqAcucar', 'coefTrocaAqVV2Acucar'],
      ipt: [true,false,false,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO VV1',
      props: ['tempOutAqVV1Acucar', 'utilAqVV1Acucar', 'consVaporVV1AqAcucar', 'coefTrocaAqVV1Acucar'],
      ipt: [true,false,false,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO VV1 CALDO CLARIFICADO',
      props: ['tempOutAqCCAcucar', 'utilAqCCAcucar', 'consVaporVV1AqCCAcucar','coefTrocaAqCCAcucar'],
      ipt: [true,false,false,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO VE CALDO CLARIFICADO',
      props: ['tempOutAqVECCAcucar', 'utilAqVECCAcucar', 'consVaporVEAqCCAcucar','coefTrocaAqVECCAcucar'],
      ipt: [true,false,false,true]
    }
  ]
}


const tratEtFlows = 
  [
    { 
      col: 1,
      sectionName: 'DOSAGEM CAL',
      props: [ 'dosagemCalEtanol'],
      ipt: [true]
    },
    { 
      col: 1,
      sectionName: 'DOSAGEM POLIMERO',
      props: ['propDosagemPolEtanol'],
      ipt: [true]
    },
    { 
      col: 2,
      sectionName: 'DECANTAÇÃO',
      props: ['lodoCaldoFlashEtanol', 'quedaTempDecantador', 'tempCaldoDecEtanol'],
      ipt: [true,true, false]
    },
    // { 
    //   col: 2,
    //   sectionName: 'DESVIO CALDO',
    //   props: ['desvioCCEtanolToAc'],
    //   ipt: [true]
    // }
  ]

function tratEtEquips(data){
  return [
    { 
      col: 1,
      sectionName: 'REGENERAÇÃO',
      props: ['tempCaldoClarReg', 'tempOutCaldoRegCaldoCaldo', 'uRegCaldoxCaldoEtanol', 'regCEtxCCEtareaReq', 'mldtRegCaldoCaldo'],
      ipt: [true,false,true, false, false] 
    },
    // col: 2,
    //   sectionName: 'AQUECIMENTO VV1',
    //   props: ['tempOutAqVV1Acucar', 'utilAqVV1Acucar', 'consVaporVV1AqAcucar', 'coefTrocaAqVV1Acucar'],
    //   ipt: [true,false,false,true]
    { 
      col: 1,
      sectionName: 'AQUECIMENTO COM VV2',
      props: ['tempOutAqVV2Etanol', 'utilAqVV2Etanol', 'consVaporVV2AqEtanol', 'coefTrocaAqVV2Etanol'],
      ipt: [true,false,false,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV1',
      props: ['tempOutAqVV1Etanol', 'utilAqVV1Etanol', 'consVaporVV1AqEtanol', 'coefTrocaAqVV1Etanol'],
      ipt: [true,false,false,true]
    }
]
}

const tratEtCap = [
  {col:1, sectionName: "REGENERAÇÃO CALDO X CALDO",
    props: ['capRegCaldoxCaldoEtanol1', 'capRegCaldoxCaldoEtanol2', 'capRegCaldoxCaldoEtanol3'],
    ipt: ['cap2', 'cap2', 'cap2']},
  {col:1, sectionName: "AQUECIMENTO VV2",
  props: ['capAqVV2Etanol1', 'capAqVV2Etanol2', 'capAqVV2Etanol3', 'capAqVV2Etanol4', 'capAqVV2Etanol5', 'capAqVV2Etanol6'],
  ipt: ['cap2', 'cap2', 'cap2', 'cap2', 'cap2', 'cap2']},
  {col:2, sectionName: "AQUECIMENTO VV1",
  props: ['capAqVV1Etanol1', 'capAqVV1Etanol2', 'capAqVV1Etanol3'],
  ipt: ['cap2', 'cap2', 'cap2']},
]

const filtEquips = [
  { 
    col: 1,
    sectionName: 'GERAL',
    props: ['prodTorta', 'perdaArtTorta', 'tempCaldoFiltrado', 'relBagacilhoCana'],
    ipt: [true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'FILTRO ROTATIVO',
    props: ['embLodoFitroRot', 'relFiltroRotativo', 'flowAguaEmbebicaoFiltroRot', 'flowLodoFiltroRotativo'],
    ipt: [true,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'FILTRO PRENSA',
    props: ['embLodoFiltroPrensa', 'flowAguaLavTelasFiltroPre', 'relFiltroPrensa', 'flowAguaEmbebicaoFiltroPre', 'flowLodoFiltroPrensa'],
    ipt: [true,true,true,false,false]
  }
]

const evapFlows = [
  { 
    col: 1,
    sectionName: 'XAROPE',
    props: ['brixXarope', 'desvioXaropeToEtanol'],
    ipt: [true,true]
  },
  // { 
  //   col: 2,
  //   sectionName: 'QUANTIDADE EFEITOS',
  //   props: ['qtdeEfeitos'],
  //   ipt: ['combo']
  // }
]

const evapEquips = [
  { 
    col: 1,
    sectionName: 'EVAPORAÇÃO AÇÚCAR',
    props: ['areaOpPreEvapAcucar', 'areaOp2EfEvapAcucar', 'areaOp3EfEvapAcucar', 'areaOp4EfEvapAcucar', 'areaOp5EfEvapAcucar'],
    ipt: [true,true,true,true,true]
  },
  { 
    col: 2,
    sectionName: 'GERAL',
    props: ['perdasTermicaEvap'],
    ipt: [true]
  },
  { 
    col: 2,
    sectionName: 'QUANTIDADE EFEITOS',
    props: ['qtdeEfeitos'],
    ipt: ['combo']
  },
  
]

const evapCap = [
  { 
    col: 1,
    sectionName: 'TAXA PRÉ-EVAPORAÇÃO',
    props: ['minTaxaEvapPreEvapAcucar', 'maxTaxaEvapPreEvapAcucar'],
    ipt: [true,true]
  },
  { 
    col: 1,
    sectionName: 'TAXA EVAPORAÇÃO 2º EFEITO',
    props: ['minTaxaEvap2Ef', 'maxTaxaEvap2Ef'],
    ipt: [true, true]
  },
  { 
    col: 1,
    sectionName: 'TAXA EVAPORAÇÃO 3º EFEITO',
    props: ['minTaxaEvap3Ef', 'maxTaxaEvap3Ef'],
    ipt: [true, true]
  },
  { 
    col: 2,
    sectionName: 'TAXA EVAPORAÇÃO 4º EFEITO',
    props: ['minTaxaEvap4Ef', 'maxTaxaEvap4Ef'],
    ipt: [true, true]
  },
  { 
    col: 2,
    sectionName: 'TAXA EVAPORAÇÃO 5º EFEITO',
    props: ['minTaxaEvap5Ef', 'maxTaxaEvap5Ef'],
    ipt: [true, true]
  }
  
]

const fermEquips = [
  { 
    col: 1,
    sectionName: 'CENTRÍFUGAS',
    props: ['eficCentrifugas', 'capacCentrifugas', 'sobraCentrifugas'],
    ipt: [true,false,false]
  },
  { 
    col: 1,
    sectionName: 'RESFRIAMENTO DORNAS',
    props: ['tempAguaFriaTorreFerm', 'tempAguaQuenteTorreFerm', 'flowAguaTorreFerm'],
    ipt: [false,false,false]
  },
  { 
    col: 2,
    sectionName: 'RESFRIAMENTO MOSTO',
    props: ['tempMostoReg', 'tempMostoResf', 'tempAguaFriaTorreFerm', 'tempAguaSaiResfMosto', 'coefTrocaResfMosto', 'flowAguaResfMosto', 'approachResfMosto', 'areaNecessariaResfMosto'],
    ipt: [false,true,false,true,true,false,false,false]
  },
  
]

const fermFlows = [
  { 
    col: 1,
    sectionName: 'PREMISSAS',
    props: ['brixMosto', 'concCelVinhoBruto', 'concCelCreme', 'concCelVinhoCent', 'taxaReciclo', 'rendCelula', 'efFermentacao'],
    ipt: [true,true,true,true,true,true,true]
  },
  // { 
  //   col: 1,
  //   sectionName: 'DILUIÇÃO MOSTO',
  //   props: [ 'tempAguaDilMosto',  'flowAguaDilMosto'],
  //   ipt: [true,false]
  // },
  { 
    col: 1,
    sectionName: 'TEMPERATURAS',
    props: ['tempXarope', 'tempMelFinal', 'tempMostoResf', 'tempAguaDilMosto'],
    ipt: [true,true,true,true]
  },
  // { 
  //   col: 2,
  //   sectionName: 'LEVEDURA',
  //   props: ['flowLeveduraTerceiros', 'relMassaSecaTerceiros', 'efLeveduraTerceiros', 'consEspVaporVMSeclevedura'],
  //   ipt: [true,true,true,true]
  // },
  { 
    col: 2,
    sectionName: 'MEL TERCEIROS',
    props: ['flowMelTerceiros', 'brixMelTerceiros', 'tempMelTerceiros', 'artMelTerceiros', 'artMassaMelTerceiros'],
    ipt: [true,true,true,true, false]
  },
  { 
    col: 2,
    sectionName: 'ESTOQUE MEL',
    props: ['flowMelpEstoque', 'flowMelEstoque', 'opPropMelEstoque', 'brixMelEstoque', 'artMelEstoque', 'tempMelEstoque'],
    ipt: [true,true,'switch', true,true, true]
  }
]

const fermCap = [
  {col:1, sectionName: "CENTRÍFUGAS VINHO",
  props: [['capCentVinho1', 'opCentVinho1'], ['capCentVinho2', 'opCentVinho2'],['capCentVinho3', 'opCentVinho3'],['capCentVinho4', 'opCentVinho4'],['capCentVinho5', 'opCentVinho5'],
  ['capCentVinho6', 'opCentVinho6'],['capCentVinho7', 'opCentVinho7'],['capCentVinho8', 'opCentVinho8'],['capCentVinho9', 'opCentVinho9'],['capCentVinho10', 'opCentVinho10']],
  ipt: ['cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap']},
  {col:2, sectionName: "RESFRIAMENTO MOSTO",
  props: [['capResfMosto1', 'opResfMosto1'], ['capResfMosto2', 'opResfMosto2'], ['capResfMosto3', 'opResfMosto3']],
  ipt: ['cap', 'cap', 'cap']},
  {col:2, sectionName: "TORRES",
    props: ['capTorreResfMosto', 'capTorreResfDornas'],
    ipt: [true, true]},
]

function destPremissas(data){
  return [
    { 
      col: 1,
      sectionName: 'GERAL',
      props: ['relEtanolSegProd', 'relOleoFuselProd', 'relPerdaDegasProducao', 'concEtanolVinhaca', 'concEtanolFlegmaca', 'concEtanolFlegma'],
      ipt: [true,true,true,true,true,true]
    },
    { 
      col: 1,
      sectionName: 'OUTROS',
      props: ['flowFlegmaca', 'flowVinhaca'],
      ipt: [false,false]
    },
    
    
    { 
      col: 2,
      sectionName: 'PRODUÇÃO',
      props: ['efDestilaria', 'flowVolEtanolProd100', 'flowEtanol', 'flowEtanolHid', 'flowEtanolAnidro', 'flowEtanolNeutro', 'flowEtanolDest'],
      ipt: [false,false,false,false,false,false, false, false]
    },
    
    
  ]
} 

function destEquips(data){
  return [
    { 
      col: 1,
      sectionName: 'PREMISSA CONSUMO VAPOR',
      props: ['consEspVaporDestManual', 'consEspVaporDestilaria', 'glVinhoBoletim', 'glVinhoConsVapor'],
      ipt: ['switch',
      data['consEspVaporDestManual'].valor === 0 ? false : true,true, 'combo'],
      comboList: ['CALCULADO', 'BOLETIM']
    },
    { 
      col: 1,
      sectionName: 'COLUNA A - CONVENCIONAL',
      props: data['vaporColAConv'].valor === 0 ? ['vaporColAConv', 'isAquecIndConv',  'relEtanolHidConvencional', 'consEspVaporColunaA', 'flowVaporVV1ColAConv', 'flowVaporVEColAConv', 'retCDColAConv'] :
      ['vaporColAConv',  'relEtanolHidConvencional', 'consEspVaporColunaA', 'flowVaporVV1ColAConv', 'flowVaporVEColAConv', 'retCDColAConv'],
      ipt: data['vaporColAConv'].valor === 0 ? ['combo', 'switch', true,false,false, false, 'switch'] : ['combo', true,false,false, false, 'switch']
    },
    { 
      col: 1,
      sectionName: 'COLUNA A - FLEGSTIL',
      props: data['vaporColAFleg'].valor === 0 ? ['vaporColAFleg', 'isAquecIndFleg', 'relEtanolHidFlegstil', 'consEspVaporColunaAFlegstil', 'flowVaporVV1ColAFleg', 'flowVaporVEColAFleg', 'retCDColAFleg'] :
      ['vaporColAFleg', 'relEtanolHidFlegstil', 'consEspVaporColunaAFlegstil', 'flowVaporVV1ColAFleg', 'flowVaporVEColAFleg', 'retCDColAFleg'],
      ipt: data['vaporColAFleg'].valor === 0 ? ['combo', 'switch', false,false,false, false, 'switch'] : ['combo', false,false,false, false, 'switch'],
    },
    { 
      col: 1,
      sectionName: 'COLUNA B',
      props: ['consEspVaporColunaB', 'consVaporVV1ColB'],
      ipt: [true, false]
    },
    { 
      col: 2,
      sectionName: 'ETANOL ANIDRO',
      props: ['mixEtanolAnidro', 'flowEtanolProdToDesid', 'flowEtanolAnidroDia', ],
      ipt: [true, false, false]
    },
    { 
      col: 2,
      sectionName: '',
      subSection: 'COLUNA C',
      props: ['consEspVaporVV1ColC', 'consVaporVV1ColC', 'retCDVGColC'],
      ipt: [true, false, 'switch']
    },
    { 
      col: 2,
      sectionName: '',
      subSection: 'COLUNA P',
      props: ['consEspVaporVV1ColP', 'consVaporVV1ColP', 'retCDVGColP'],
      ipt: [true, false, 'switch']
    },
    { 
      col: 2,
      sectionName: 'ETANOL NEUTRO',
      props: ['mixEtanolNeutro', 'flowEtanolProdToNeutro', 'flowEtanolNeutroDia', 'consEspVaporVV1ColA2', 'consVaporVV1ColA2', 'retCDVGColA2'],
      ipt: [true, false, false, true, false, 'switch']
    },
    { 
      col: 2,
      sectionName: 'ETANOL HIDRATADO DESTILADO (AGUARDENTE)',
      props: ['mixEtanolDest', 'flowEtanolProdToDest', 'flowEtanolDestDia', 'consEspVaporVV1ColDest', 'consVaporVV1ColDest', 'retCDVGColDest'],
      ipt: [true, false, false, true, false, 'switch']
    },
  ]
} 

const flotPrem = [
  { col: 1, sectionName: "FLOTADOR",
    props: ['relBorraXarope', 'brixBorraFlotador', 'flowBorraFlotador'],
    ipt: [true, true, false]},
  { col: 1, sectionName: "ÁGUA DOCE",
    props: ['flowAguaDoceBorra', 'brixAguaDoceBorra', 'tempAguaDoceBorra'],
    ipt: [true, true, true]},
  { col: 2,
    sectionName: 'AQUECEDOR XAROPE',
    props: ['tempXaropeAquec', 'utilAqVV1Xarope', 'consVaporVV1AquecXarope', 'coefTrocaAqXarope'],
    ipt: [true,false,false,true]
  }
]

const flotCap = [
  { col: 1, sectionName: "FLOTADOR",
    props: ['flowXaropeFlotado'],
    ipt: ['max'],
    desc: ['Max. Vazão Xarope Flotado']},
  { col: 2, sectionName: "AQUECIMENTO XAROPE",
    props: ['capAqVV1Xarope1', 'capAqVV1Xarope2'],
    ipt: ['cap2', 'cap2']},
]

const fabPrem = [
  { col: 1, sectionName: "PUREZA",
    props: ['purezaXarope', 'purezaMelARico', 'purezaMelAPobre', 'purezaMelBRico', 'purezaMelFinal', 'purezaAcucar', 'purezaAcucarB', 'purezaMagma'],
    ipt: [true, true, true, true, true, true, true, true]},
  { col: 1, sectionName: "DIVISÃO DE MÉIS",
    props: ['marRelTotal', 'mbrRelTotal'],
    ipt: [true, true, true]},
  { col: 1, sectionName: "DIVISÃO DE XAROPE",
    props: ['xaropeToMassaA', 'xaropeToMassaB'],
    ipt: [true, false]},
  { col: 1, sectionName: "VAPOR",
    props: ['relVaporAguaCoz', 'relVaporAguaCozCont'],
    ipt: [true, true]},
  { col: 2, sectionName: "BRIX",
    props: ['brixMassaA', 'brixMassaB', 'brixMelARico', 'brixMARDiluido', 'brixMelAPobre', 'brixMAPDiluido', 'brixMelBRico', 'brixMelBRicoDil', 'brixMelFinal', 'brixAcucar', 'brixAcucarB', 'brixMagma'],
    ipt: [true, true, true, true, true, true, true, true, true, true, true, true]},
  { col: 2, sectionName: "CALCULOS",
    props: ['recupSJM', 'concCristaisMassaA', 'concCristaisMassaB', 'retencaoMassaA', 'retencaoMassaB'],
    ipt: [false, false, false, false, false]},
]

// const fabFlows = [
//   { 
//     col: 1,
//     sectionName: 'PUREZA',
//     props: ['purezaXarope', 'purezaMelARico', 'purezaMelAPobre', 'purezaMelFinal', 'purezaAcucar', 'purezaAcucarB', 'purezaMagma'],
//     ipt: [true,true,true,true,true,true,true]
//   },
//   { 
//     col: 1,
//     sectionName: 'BRIX',
//     props: ['brixMassaA', 'brixMelARico', 'brixMARDiluido', 'brixMelAPobre', 'brixMAPDiluido', 'brixMelFinal', 'brixMagma', 'brixMassaB', 'brixAcucarB', 'brixAcucar'],
//     ipt: [true,true,true,true,true,true,true]
//   },
//   { 
//     col: 2,
//     sectionName: 'DIVISÃO DE XAROPE',
//     props: ['xaropeToMassaA', 'xaropeToMassaB'],
//     ipt: [true, false]
//   },
//   { 
//     col: 2,
//     sectionName: 'OPCÃO VAPOR',
//     props: ['qtdeCozMassaAVV1'],
//     ipt: ['combo']
//   },
//   { 
//     col: 2,
//     sectionName: 'CALCULOS',
//     props: ['recupSJM', 'concCristaisMassaA', 'concCristaisMassaB'],
//     ipt: [false,false,false]
//   }
// ]

const fabEquips = [
  {col:1, sectionName: "COZEDORES MASSA A",
  props: ['ciclosMassaA', 'consVaporVV1CozedorMassaA', 'consVaporVV2CozedorMassaA'],
  ipt: [false, false, false]},
  {col:1, sectionName: "COZEDORES MASSA B",
  props: ['tempoCozimentoMassaB',  'consVaporVV2CozedorMassaB'],
  ipt: [false, false]},
  {col:1, sectionName: "SECADOR",
  props: ['consEspVaporSecadorAcucar', 'consVaporVESecadorAcucar', 'flowAcucarSecador'],
  ipt: [true, false, false]},
  {col:2, sectionName: "CENTRÍFUGAS MASSA A",
  props: ['ciclosCentMassaA', 'efCentMassaA', 'capTotalCentMassaA', 'sobraCentMassaA'],
  ipt: [true, true, false, false]},
  {col:2, sectionName: "CENTRÍFUGAS MASSA B",
  props: ['efCentMassaB', 'capTotalCentMassaB', 'sobraCentMassaB'],
  ipt: [true, false, false]}
]

const fabCap = [
    {col:1, sectionName: "COZEDORES MASSA A",
    props: [['capCozMA1', 'vapCozMA1', 'opCozMA1', 'V2V1'], ['capCozMA2', 'vapCozMA2', 'opCozMA2', 'V2V1'], ['capCozMA3', 'vapCozMA3', 'opCozMA3', 'V2V1'], ['capCozMA4', 'vapCozMA4', 'opCozMA4', 'V2V1'], ['capCozMA5', 'vapCozMA5', 'opCozMA5', 'V2V1'], ['capCozMA6', 'vapCozMA6', 'opCozMA6', 'V2V1'], ['capCozMA7', 'vapCozMA7', 'opCozMA7', 'V2V1'], ['capCozMA8', 'vapCozMA8', 'opCozMA8', 'V2V1'], 'maxCiclosCozMA'],
    ipt: ['capVap', 'capVap', 'capVap', 'capVap', 'capVap', 'capVap', 'capVap', 'capVap',  true]},
    {col:1, sectionName: "CENTRÍFUGAS MASSA A",
    props: [['capCentMA1', 'opCentMA1'], ['capCentMA2', 'opCentMA2'], ['capCentMA3', 'opCentMA3'], ['capCentMA4', 'opCentMA4'], ['capCentMA5', 'opCentMA5'],
    ['capCentMA6', 'opCentMA6'], ['capCentMA7', 'opCentMA7'], ['capCentMA8', 'opCentMA8'], ['capCentMA9', 'opCentMA9'], ['capCentMA10', 'opCentMA10']],
    ipt: ['cap', 'cap', 'cap', 'cap','cap', 'cap', 'cap', 'cap','cap', 'cap']},
    {col:2, sectionName: "COZEDORES MASSA B",
        props: ['capCozedorContinuo', 'tempoCozimentoMassaB', 'tempoCozimentoMassaB'],
        ipt: ['cap2', 'min', 'max'],
      desc: ['', 'Mín. Tempo Cozimento', 'Máx. Tempo Cozimento']},
    {col:2, sectionName: "CENTRÍFUGAS MASSA B",
        props: [['capCentMB1', 'opCentMB1'], ['capCentMB2', 'opCentMB2'], ['capCentMB3', 'opCentMB3'], ['capCentMB4', 'opCentMB4'], ['capCentMB5', 'opCentMB5'],
        ['capCentMB6', 'opCentMB6'], ['capCentMB7', 'opCentMB7']],
        ipt: ['cap', 'cap', 'cap', 'cap','cap', 'cap', 'cap']},
    {col:2, sectionName: "SECADOR",
        props: ['maxFlowAcucarSecador'],
        ipt: [true]},
  ]

function vapPrem(data){
  return [
   
    { 
      col: 1,
      sectionName: 'ENERGIA',
      props: ['ptGerEnergia', 'expEnergia', 'expEnergiaTC', 'consEnergia', 'consEspEnergiaTC'],
      ipt: [false, false, false, false, true]
    },
    { 
      col: 1,
      sectionName: 'PRESSÕES',
      props: ['pressureVE', 'pressureVV1', 'pressureVV2', 'pressureVV3', 'pressureVV4', 'pressureVV5'],
      ipt: [true,true,true,true,true,true,true]
    },
    { 
      col: 1,
      sectionName: 'TEMPERATURAS',
      props: ['tempVaporVE', 'tempDesaerador', 'tempRetCondVEDesaerador', 'tempsAguaDesmiDesaerador'],
      ipt: [true, true, true, true]
    },
    
    { 
      col: 2,
      sectionName: 'PERDAS',
      props: ['perdasVM', 'perdasVE', 'perdasVV1', 'perdasVV2', 'perdasVV3'],
      ipt: [true, true, true, true, true]
    },
    { 
      col: 2,
      sectionName: 'ALÍVIO',
      props: ['consVaporVEAlivio', 'consVaporVV1Alivio', 'consVaporVV2Alivio'],
      ipt: [false, true, true]
    },
    { 
      col: 2,
      sectionName: 'DESAERADOR',
      props: ['retornoCondVEProcesso',   'consVaporVEDesaerador'],
      ipt: [true, false]
    },
    
    
  ]
} 

// const vapTGs = 
//  [{ 
//       col: 1,
//       sectionName: 'TG2',
//       props: ['ptTgCpVA', 'consVaporVATgCpVA', 'pressInTgCpVA', 'tempInTgCpVA', 'pressEscTgCpVA', 'tempEscTgCpVA', 'gerVaporVETgCpVA', 'consEspTgCpVA', 'eficIsoTgCpVA'],
//       ipt: [false, false, true, true, true, true, false, false, false]
//   },
//   { 
//     col: 2,
//     sectionName: 'TG1',
//     props: ['ptTgCpVM', 'consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM', 'pressEscTgCpVM', 'tempEscTgCpVM', 'gerVaporVETgCpVM', 'consEspTgCpVM', 'eficIsoTgCpVM'],
//     ipt: [false, false, true, true, true, true, false, false, false]
// }]

function vapTGs(data){
  return [
    { 
      col: 1,
      sectionName: 'TG 6',
      props: ['ptTgCpVA', 'consVaporVATgCpVA', 'pressInTgCpVA', 'tempInTgCpVA', 'pressEscTgCpVA', 'tempEscTgCpVA', 'gerVaporVETgCpVA', 'consEspTgCpVA', 'eficIsoTgCpVA'],
      ipt: [false, false, false, false, true, true, false, false, false]
    },
    { 
      col: 2,
      sectionName: 'TG 3',
      props: ['ptTgCdVA', 'consVaporVATgCdVA', 'pressInTgCdVA', 'tempInTgCdVA', 'gerCondVETgCdVA', 'pressEscTgCdVA', 'opModeTgCdVA',
      data['opModeTgCdVA'].valor === 1 ? 'titEscTgCdVA' : '', 'tempEscTgCdVA', 'eficIsoTgCdVA', 'consEspTgCdVA'
      ],
      ipt: [true, false, false, false, false, true, 'switch', 
      data['opModeTgCdVA'].valor === 1 ? true : '',
      data['opModeTgCdVA'].valor === 1 ? false : true, false, false 
    ]
    },
    
  ]
} 


function vapBoilers(data){
  return [
    // { col: 1,
    //   sectionName: 'CALDEIRA VA',
    //   subSection: 'GERAL',
    //   props: ['opCalcCaldeirasVA', 'gerVaporVACaldeiras', 'consBagCaldeirasVA', 'pressureVA', 'tempVaporVA', 'purgaDesCaldeirasVA'],
    //   ipt: ['switch', false, false, true, true, true]
    // },
    // { col: 1,
    //   sectionName: '',
    //   subSection: 'PERFORMANCE',
    //   props: data['opCalcCaldeirasVA'].valor === 0 ? ['rendCaldeirasVA', 'efPCICaldeirasVA'] : 
    //   ['o2CaldeirasVA', 'tempGasesCaldeirasVA', 'perdasNqCaldeirasVA', 'perdasIrrCaldeirasVA', 'excessoArCaldeirasVA', 'rendCaldeirasVA', 'efPCICaldeirasVA'],
    //   ipt: data['opCalcCaldeirasVA'].valor === 0 ? [true, false] :
    //   [true, true, true, true, false, false, false]
    // },
    // { col: 1,
    //   sectionName: '',
    //   subSection: 'TURBO-BOMBA',
    //   props: data['opTurboBombaCaldeirasVA'].valor === 0 ? ['opTurboBombaCaldeirasVA'] : 
    //   ['opTurboBombaCaldeirasVA', 'consEspTBCaldeirasVA', 'tempVETBCaldeirasVA', 'ptTBCaldeirasVA', 'consVaporVMTBCaldeirasVA'],
    //   ipt: data['opTurboBombaCaldeirasVA'].valor === 0 ? ['switch'] :
    //   ['switch', true, true, false, false]
    // },
    
  //   { 
  //     col: 1,
  //     sectionName: 'TURBO GERADOR',
  //     props: ['consEspTgCpVM', 'tempEscTgCpVM', 'ptTgCpVM', 'consVaporVMTgCpVM'],
  //     ipt: [true, true, false, false]
  // },
    { col: 1,
      sectionName: 'CALDEIRAS',
      subSection: 'GERAL',
      props: ['opCalcCaldeirasVA', 'gerVaporVACaldeiras', 'consBagCaldeirasVA', 'pressureVA', 'tempVaporVA', 'purgaDesCaldeirasVA'],
      ipt: ['switch', false, false, true, true, true]
    },
    { col: 1,
      sectionName: '',
      subSection: 'PERFORMANCE',
      props: data['opCalcCaldeirasVA'].valor === 0 ? ['rendCaldeirasVA', 'efPCICaldeirasVA'] : 
      ['o2CaldeirasVA', 'tempGasesCaldeirasVA', 'perdasNqCaldeirasVA', 'perdasIrrCaldeirasVA', 'excessoArCaldeirasVA', 'rendCaldeirasVA', 'efPCICaldeirasVA'],
      ipt: data['opCalcCaldeirasVA'].valor === 0 ? [true, false] :
      [true, true, true, true, false, false, false]
    },
    { col: 1,
      sectionName: '',
      subSection: 'TURBO-BOMBA',
      props: data['opTurboBombaCaldeirasVA'].valor === 0 ? ['opTurboBombaCaldeirasVA'] : 
      ['opTurboBombaCaldeirasVA', 'consEspTBCaldeirasVA', 'tempVETBCaldeirasVA', 'ptTBCaldeirasVA', 'consVaporVMTBCaldeirasVA'],
      ipt: data['opTurboBombaCaldeirasVA'].valor === 0 ? ['switch'] :
      ['switch', true, true, false, false]
    }
  ]
} 

const vapCap = [
  // {col:1, sectionName: "CALDEIRA VA",
  // props: ['opCaldVA', 'gerVaporVACaldeiras', 'gerVaporVACaldeiras'],
  // ipt: ['switch', 'max', 'min'],
  // desc: ['', 'Vazão Máxima', 'Vazão Mínima']},
  {col:1, sectionName: "CALDEIRAS",
    props: ['maxCaldeiraVA_I', 'maxCaldeiraVA_II'],
    ipt: ['cap2', 'cap2']},
  // {col:1, sectionName: "CALDEIRAS",
  //   props: ['gerVaporVMCaldeiras', 'gerVaporVMCaldeiras'],
  //   ipt: ['max', 'min'],
  //   desc: ['Vazão Máxima', 'Vazão Mínima']},
  {col:1, sectionName: "REBAIXADORAS",
  props: ['gerVaporVMRebVaVm', 'gerVaporVERebVmVe'],
  ipt: ['max', 'max'],
  desc: ['Capacidade Rebaixadora V67/V21', 'Capacidade Rebaixadora V21/VE']},
  {col:2, sectionName: "TG6",
    props: ['ptTgCpVA', 'ptTgCpVA', 'consVaporVATgCpVA', 'consVaporVATgCpVA'],
    ipt: ['max', 'min', 'max', 'min'],
    desc: ['Potência Máxima', 'Potência Mínima', 'Vazão Vapor Máxima', 'Vazão Vapor Mínima']},
    {col:2, sectionName: "TG3",
      props: ['ptTgCdVA', 'ptTgCdVA', 'consVaporVATgCdVA', 'consVaporVATgCdVA'],
      ipt: ['max', 'min',  'max', 'min'],
      desc: ['Potência Máxima', 'Potência Mínima', 'Vazão Vapor Máxima', 'Vazão Vapor Mínima']},
    
]

const bhPrem = [
  { 
    col: 1,
    sectionName: 'ÁGUA BRUTA',
    props: ['consAguaBrutaGeral'],
    ipt: [true]
  },
  { 
    col: 1,
    sectionName: 'ÁGUA TRATADA',
    props: ['consAguaTratGeral', 'consAguaTratEvap', 'consAguaTratLimpEvap'],
    ipt: [true,true,true]
  },
  { 
    col: 1,
    sectionName: 'CONDENSADO VEGETAL',
    props: ['consCDVGUsoGeral'],
    ipt: [true]
  },
  // { 
  //   col: 1,
  //   sectionName: 'EMBEBIÇÃO',
  //   props: ['tempEmbeb'],
  //   ipt: [true]
  // },
  { 
    col: 2,
    sectionName: 'SISTEMA LAVAGEM GASES',
    props: ['relAguaVapor', 'tempAguaEntLavadorGases', 'tempAguaSaidaLavadorGases', 'flowFuligemCaldeira', 'umidFuligemCaldeira', 'flowAguaLavadorGases', 'flowAguaEvapLavadorGases', 'flowAguaFuligem', 'consAguaResRepSistFuligem'],
    ipt: [true,true,true,true,true,false,false,false,false]
  },
]

const bhEquips = [
  { 
    col: 1,
    sectionName: 'TORRE MOSTO / DORNAS / DESTILARIA',
    props: ['tempAguaFriaTorreFerm', 'tempAguaSaiResfMosto', 'tempAguaQuenteTorreFerm', 'tempAguaQuenteTorreDest', 'tempAguaQuenteTotalTorreDest', 'perdasArrasteTorreFerm',
    'perdasPurgasTorreFerm', 'flowAguaResfMosto', 'flowAguaTorreFerm', 'flowAguaTotalTorreDest', 'flowPerdaTotalArrasteTorreDest', 'flowPerdaTotalEvapTorreDest', 'gerAguaResPurgasTorreDest', 'consAguaBrutaRepTorreDest'
  ],
    ipt: [true,true,true,true,false,true,true,false,false,false,false,false,false,false]
  },
  { 
    col: 1,
    sectionName: 'TORRE VINHAÇA',
    props: ['tempVinhacaReg', 'tempVinhacaFriaTorreVinhaca', 'flowVinhaca', 'perdasArrasteTorreVinhaca', 'flowPerdaArrasteTorreVinhaca', 'flowPerdaEvapTorreVinhaca', 'flowVinhacaFria'],
    ipt: [false,true,false,true,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'TORRE FÁBRICA',
    props: ['tempAguaFriaTorreFab', 'tempAguaQuenteTorreFab', 'perdasArrasteTorreFab', 'perdasPurgasTorreFab', 'flowAguaEvapTorreFab', 'flowAguaMassaATorreFab', 'flowAguaMassaBTorreFab',
    'flowAguaTorreFab', 'flowPerdaArrasteTorreFab', 'gerAguaResPurgasTorreFab', 'consAguaBrutaRepTorreFab', 
  ],
    ipt: [true,true,true,true,false,false,false,false,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'TORRE MANCAIS',
    props: ['tempAguaFriaTorreMancais', 'tempAguaQuenteTorreMancais', 'perdasArrasteTorreMancais', 'perdasPurgasTorreMancais', 'flowAguaTorreMancais', 'flowPerdaEvapTorreMancais', 'flowPerdaArrasteTorreMancais', 
    'gerAguaResPurgasTorreMancais', 'consAguaBrutaRepTorreMancais'],
    ipt: [true,true,true,true,true,false,false,false,false]
  },
]


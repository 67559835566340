import PsChartsDataSEL from "../usSantaElisa/Plano Safra/PsChartsDataSEL"
import PsChartsDataUBT from "../usBatatais/Plano Safra/PsChartsDataUBT"
import PsChartsDataSON from "../usSonora/Plano Safra/PsChartsDataSON"
import PsChartsDataUMA from "../usMonteAlegre/Plano Safra/PsChartsDataUMA"
import PsChartsDataCVS from "../usCevasa/Plano Safra/PsChartsDataCVS"
import PsChartsDataUSL from "../usLins/Plano Safra/PsChartsDataUSL"
import PSChartsDataTST1 from "../usTeste1/Plano Safra/PsChartsDataTST1"
import PSChartsDataJBA from '../usJbAlcoolquimica/Plano Safra/PsChartsDataJBA'

export async function getPsChartsData(site){
    switch (site){
        case 'usSantaElisa': return PsChartsDataSEL
        case 'usBatatais': return PsChartsDataUBT
        case 'usSonora': return PsChartsDataSON
        case 'usMonteAlegre': return PsChartsDataUMA
        case 'usCevasa': return PsChartsDataCVS
        case 'usLins': return PsChartsDataUSL
        case 'usJbAlcoolquimica': return PSChartsDataJBA
        case 'usTeste1': return PSChartsDataTST1
        default: return null
    }
}
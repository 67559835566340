import React, { useContext, useState, useRef, useEffect} from 'react'

import '../css/planoSafra.css';
import GlobalStateContext from '../components/GlobalStateContext'
import PSContext from './opoio/PSContext';
import MenuPlanoSafra from './menuPlanoSafra';
import { UseColors } from '../components/ui/colors';
// import { stdPlanoVars } from './stdPlanoSafra'
// import { getStdPlanoSafra } from '../BME/Unidades/Geral/getStdPlanoSafra';
import SubMenuNew from './subMenus/subMenuNew';
import SubMenuEdit from './subMenus/subMenuEdit';
import SubMenuCopy from './subMenus/subMenuCopy';
// import SubMenuFiles from './subMenus/subMenuFiles';
// import PsInput from './opoio/psInput';
// import { stdValue } from '../Resources/setNumber';
// import { checkReal } from './opoio/calcPS'
// import { listaDNECods, inputCods} from '../Resources/Constantes'
// import SubMenuCharts from './subMenus/subMenuCharts';
// import { usePsCalcs } from '../BME/Unidades/Geral/usePsCalcs';
import BodyPlanoSafra from './bodyPlanoSafra';

function PlanoSafra() {
  const {isNew, isEdit, isSaveAs, isOpen, isChart, psData, psDataReal, editingMonth} = useContext(PSContext)
  const {site, setMenu, setArea} = useContext(GlobalStateContext)
  // const psCalcs = usePsCalcs() 
  // const [groupData, setGroupData] = useState(null)
  // const [headData, setHeadData] = useState(getHeadData())
  // const stdPlanoVars = getStdPlanoSafra(site)
  

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    setMenu('ps')
    setArea('planoSafra')
  }, []);

  const COLORS = UseColors()


  // useEffect(() => {
  //   // console.log('Update Plano Safra Screen', psData)
  //   if (psData){
  //     const groupedItems = psData.reduce((acc, item) => {
  //       if (stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show !== false && stdPlanoVars.filter(row => row.cod === item.codigo).length > 0){
  //         // if (sections.includes('all') || sections.includes(item.secao)){
  //           if (!acc[item.secao]) {
  //             acc[item.secao] = [];
  //         }
  //           acc[item.secao].push(item);
  //         // }
  //       }
  //       return acc;
  //   }, {});
  //   // console.log('groupedItems', groupedItems)
  //   setGroupData(groupedItems)
  //   setHeadData(getHeadData())
  //   } 
    
    
  // }, [psData, editingMonth, stdPlanoVars]);

  // let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  


  // function getHeadData(){
  //   let psAlerts = psData?.filter(item => item.codigo === 'psAlerts')

  //   if (psAlerts && psData){
      
  //     let alerts = [
  //       {status: psCalcs.checkReal(psData, 'jan') ? 'Real' : 'Plano', mes: 'Jan', alert: psAlerts[0]?.jan === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'fev') ? 'Real' : 'Plano', mes: 'Fev', alert: psAlerts[0]?.fev === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'mar') ? 'Real' : 'Plano', mes: 'Mar', alert: psAlerts[0]?.mar === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'abr') ? 'Real' : 'Plano', mes: 'Abr', alert: psAlerts[0]?.abr === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'mai') ? 'Real' : 'Plano', mes: 'Mai', alert: psAlerts[0]?.mai === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'jun') ? 'Real' : 'Plano', mes: 'Jun', alert: psAlerts[0]?.jun === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'jul') ? 'Real' : 'Plano', mes: 'Jul', alert: psAlerts[0]?.jul === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'ago') ? 'Real' : 'Plano', mes: 'Ago', alert: psAlerts[0]?.ago === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'set') ? 'Real' : 'Plano', mes: 'Set', alert: psAlerts[0]?.set === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'out') ? 'Real' : 'Plano', mes: 'Out', alert: psAlerts[0]?.out === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'nov') ? 'Real' : 'Plano', mes: 'Nov', alert: psAlerts[0]?.nov === 1 ? false : true},
  //       {status: psCalcs.checkReal(psData, 'dez') ? 'Real' : 'Plano', mes: 'Dez', alert: psAlerts[0]?.dez === 1 ? false : true}
  //     ]
  //     return alerts
  //   }else{
  //     let blkAlerts = [
  //       {status: 'Plano', mes: 'Jan', alert: true},
  //       {status: 'Plano', mes: 'Fev', alert: true},
  //       {status: 'Plano', mes: 'Mar', alert: true},
  //       {status: 'Plano', mes: 'Abr', alert: true},
  //       {status: 'Plano', mes: 'Mai', alert: true},
  //       {status: 'Plano', mes: 'Jun', alert: true},
  //       {status: 'Plano', mes: 'Jul', alert: true},
  //       {status: 'Plano', mes: 'Ago', alert: true},
  //       {status: 'Plano', mes: 'Set', alert: true},
  //       {status: 'Plano', mes: 'Out', alert: true},
  //       {status: 'Plano', mes: 'Nov', alert: true},
  //       {status: 'Plano', mes: 'Dez', alert: true}
  //     ]
  //     return blkAlerts
  //   } 
  // } 

  // function getRealItem(cod){
  //   let item = psDataReal[psData[0].safra]['13_Realizado'][cod][0]
  //   return item
  // }

  return (
    <>
    <div>
        <MenuPlanoSafra/>
        {isNew && <SubMenuNew/>}
        {isEdit && <SubMenuEdit/>}
        {isSaveAs && <SubMenuCopy/>}
        
{/*         
        {psData && groupData && !isOpen && !isChart && <div className='psTableHead'>
            <div style={{width: '15%', textAlign: 'left'}}>Descrição</div>
            <div style={{width: '5%', textAlign: 'left'}}>Un.</div>
            {headData.map((item, index) => {
                return(
                    <div className='psHeadElement' style={{width: '5%'}} key={item.mes}>
                        <div>{item.status}</div>
                        <div>{item.mes}</div>
                        {item.alert === true && 
                        <div className='psAlert'>
                            !
                            </div>}
                    </div>
                )
            })}
            <div style={{width: '10%', textAlign: 'center'}}>Acumulado</div>
        </div>} */}
      </div>
{/* 
        {isChart && <SubMenuCharts/>}
        {isOpen && <SubMenuFiles/>}
        <div className='psBody'>
        {psData &&  groupData && !isOpen && !isChart &&
         Object.keys(groupData).map(group => {
          if (psData[0].revisao === '13_Realizado' && group === 'DIAS NÃO EFETIVOS'){
          }else{
            return(
              <div key={group}>
                {group !== 'settings' && <div className='psSectionHead'>{group}</div>}
                {groupData[group].map((item, index) => {
                  if (item.codigo === 'psAlerts' || stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show === false ){
                   
                    return(<></>)
                  }
  
                  if (isEdit && psData[0].revisao === '13_Realizado'){
                    return(
                      <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                      <div style={{width: '15%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.desc}</div>
                      <div style={{width: '5%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.unid}</div>
  
                      {months.map(mes => {
                          return (
                            <div style={{width: '5%', textAlign: 'left'}}>{
                              setMonth(editingMonth) === mes && 
                              inputCods.includes(item.codigo) ?
                              <PsInput cod={item.codigo} mes={mes} valor={item[mes]}/> :
                              listaDNECods.includes(item.codigo) ?
                              null :
                              stdValue(item[mes])
                            }</div>
                          )
                      })}
                    
                      <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                    </div>
                    )
                  }
  
                  if (!isEdit && psData[0].revisao === '13_Realizado'){
                    return(
                      <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                      <div style={{width: '15%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.desc}</div>
                      <div style={{width: '5%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.unid}</div>
                      {months.map(mes => {
                         return(<div style={{width: '5%', textAlign: 'center'}}>{listaDNECods.includes(item.codigo) ? null : stdValue(item[mes])}</div>)
                      })}
                      <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                    </div>
                    )
                  }
  
                  if (isEdit){
                    return(
                      <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                      <div style={{width: '15%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.desc}</div>
                      <div style={{width: '5%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.unid}</div>
  
                      {months.map(mes => {
                          return <div style={{width: '5%', textAlign: 'left'}}>{isIpt(psData, mes, item.input)  ?
                            <PsInput cod={item.codigo} mes={mes} valor={stdValue(item[mes])}/> : 
                            stdValue(item[mes])
                            }</div>
                      })}
                    
                      <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                    </div>
                    )
                  }
                  
                    return(
                      <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
                      <div style={{width: '15%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.desc}</div>
                      <div style={{width: '5%', textAlign: 'left'}}>{stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.unid}</div>
  
                      {months.map(mes => {
                          return  <div style={{width: '5%'}} key={mes}>{stdValue(item[mes])}</div>
                      })}
                      <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                    </div>
                    )
                })}
              
              </div>
            )
          }
        })}

        
        </div> */}


       
      <BodyPlanoSafra/>
        
    </>
  )
}

export default PlanoSafra

// function isIpt(psData, mes, ipt){
  
//   let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
//   let revisao = psData[0].revisao
//   if (revisao === '13_Realizado'){
//     return true
//   }
//   // console.log('isIPT: ', mes, ipt, revisao, )
//   if (revisao === '0_Inicial' && ipt){
    
//     return true
//   }
//   let month = revisao.slice(-3).toLowerCase();
//   let index = months.indexOf(month);
//   let index1 = months.indexOf(mes);
  
//   if (index1 < index){
//     return false
//   }

//   if (ipt){
//     return true
//   }
  
// }

// function setMonth(fullMonth){
//   let month = fullMonth.substring(0, 3).toLowerCase()
//   return month
// }


function getAlertList(data){
  let listAlerts = []
    if (!data){
      return listAlerts
    }
    let listKeys = Object.keys(data)
    
    listKeys.map((item) => {
      if (data[item].valor){

        if (data[item].limiteMin !== null){
          if (item === 'taxaEvap5EfEvapAcucar'){
            console.log('taxaEvap5EfEvapAcucar', data[item].valor)
          }
          if (data[item].valor !== 0 && data[item].valor < data[item].limiteMin){
            listAlerts.push(data[item])
          }
      }

      if (data[item].limiteMax !== null){
        if (data[item].valor > data[item].limiteMax){
          listAlerts.push(data[item])
        }
      }

      }
        
    })
    

    return listAlerts
}


module.exports = {
    getAlertList
  }
import { getUsSantaElisaFlxsData } from "../usSantaElisa/usSantaElisaFlxsData";
import { getUsBatataisFlxsData } from "../usBatatais/usBatataisFlxsData";
import { getUsSonoraFlxsData } from "../usSonora/usSonoraFlxsData";
import { getUsMonteAlegreFlxsData } from "../usMonteAlegre/usMonteAlegreFlxsData";
import { getUsCevasaFlxsData } from "../usCevasa/usCevasaFlxsData";
import { getUsLinsFlxsData } from "../usLins/usLinsFlxsData";
import { getUsTeste1FlxsData } from "../usTeste1/usTeste1FlxsData"
import { getUsJbaFlxsData } from '../usJbAlcoolquimica/usJbaFlxsData'

export function getFlxData(area, screen, data, site){
    // console.log('getFlxData', area, screen, data, site)
    switch (site){
        case 'usSantaElisa': return getUsSantaElisaFlxsData(area, screen, data)
        case 'usBatatais': return getUsBatataisFlxsData(area, data)
        case 'usSonora': return getUsSonoraFlxsData(area, data)
        case 'usMonteAlegre': return getUsMonteAlegreFlxsData(area, data)
        case 'usCevasa': return getUsCevasaFlxsData(area, data)
        case 'usLins': return getUsLinsFlxsData(area, data)
        case 'usJbAlcoolquimica': return getUsJbaFlxsData(area, screen, data)
        case 'usTeste1': return getUsTeste1FlxsData(area, data)
        default: return null
    }
}
import TenYearChartDataSEL from '../usSantaElisa/Plano Safra/TenYearChartDataSEL'
import TenYearChartsDataUBT from '../usBatatais/Plano Safra/TenYearChartsDataUBT'
import TenYearChartsDataSON from '../usSonora/Plano Safra/TenYearChartsDataSON'
import TenYearChartsDataUMA from '../usMonteAlegre/Plano Safra/TenYearChartsDataUMA'
import TenYearChartsDataCVS from '../usCevasa/Plano Safra/TenYearChartsDataCVS'
import TenYearChartsDataUSL from '../usLins/Plano Safra/TenYearChartsDataUSL'
import TenYearChartsDataTST1 from '../usTeste1/Plano Safra/TenYearChartsDataTST1'
import TenYearChartsDataJBA from '../usJbAlcoolquimica/Plano Safra/TenYearChartsDataJBA'

export async function get10YearChartData(site){
    
    switch (site){
        case 'usSantaElisa': return TenYearChartDataSEL
        case 'usBatatais': return TenYearChartsDataUBT
        case 'usSonora': return TenYearChartsDataSON
        case 'usMonteAlegre': return TenYearChartsDataUMA
        case 'usCevasa': return TenYearChartsDataCVS
        case 'usLins': return TenYearChartsDataUSL
        case 'usJbAlcoolquimica': return TenYearChartsDataJBA
        case 'usTeste1': return TenYearChartsDataTST1
        default: return null
    }
}
import { stdPlanoVarsUBT } from '../usBatatais/Plano Safra/stdPlanoSafraUBT'
import { stdPlanoVarsSEL } from '../usSantaElisa/Plano Safra/stdPlanoSafraSEL'
import { stdPlanoVarsSON } from '../usSonora/Plano Safra/stdPlanoSafraSON'
import { stdPlanoVarsUMA } from '../usMonteAlegre/Plano Safra/stdPlanoVarsUMA' 
import { stdPlanoVarsCVS } from '../usCevasa/Plano Safra/stdPlanoSafraCVS'
import { stdPlanoVarsUSL } from '../usLins/Plano Safra/stdPlanoSafraUSL'
import { stdPlanoVarsTST1 } from '../usTeste1/Plano Safra/stdPlanoSafraTST1'
import { stdPlanoVarsJBA } from '../usJbAlcoolquimica/Plano Safra/stdPlanoSafraJBA'

export function getStdPlanoSafra(site){
    switch (site){
        case 'usSantaElisa': return stdPlanoVarsSEL
        case 'usBatatais': return stdPlanoVarsUBT
        case 'usSonora': return stdPlanoVarsSON
        case 'usMonteAlegre': return stdPlanoVarsUMA
        case 'usCevasa': return stdPlanoVarsCVS
        case 'usLins': return stdPlanoVarsUSL
        case 'usJbAlcoolquimica': return stdPlanoVarsJBA
        case 'usTeste1': return stdPlanoVarsTST1
        default: return stdPlanoVarsSEL
    }
}
import React, { useContext, useEffect, useState } from 'react';
import GlobalStateContext from './GlobalStateContext';
import { UseColors } from './ui/colors';
import { getDefData } from '../BME/Unidades/Geral/getDefData';
import LZString from 'lz-string'
import AnalyticsHooks from '../Hooks/analyticsHooks';

const ListaSites = () => {
    const {user, site, setSite, setData, data} = useContext(GlobalStateContext)
    const {newAction} = AnalyticsHooks()
    const [nSites, setNSites] = useState(user.sites.length)

    //  global.endPoint = 'https://pms-api-santaelisa.onrender.com'
  global.endPoint = 'https://pms-api-geral.onrender.com'
  // global.endPoint = 'HTTP://localhost:7096'

  async function handleNomeClick(site) {
    // localStorage.setItem(`BmeData${site}`, null);
    const savedAcData = LZString.decompress(localStorage.getItem(`BmeData${site}`));
    let defData = await getDefData(site);
    // const savedBmeData = localStorage.getItem(`BmeData${site}`);
    let result = savedAcData;

    if (!result) { // Verifica se result é null, undefined ou string vazia
        result = defData;
        console.log('YESS', result, site);
    } else {
        try {
            result = JSON.parse(result); // Tenta fazer o parse do JSON
            // console.log('Result', result, defData, Object.keys(result).length, Object.keys(defData).length)
            // if (Object.keys(result).length !== Object.keys(defData).length) {
              await addNewElements(result, defData);
              await removeElements(result, defData)
          // }
        } catch (error) {
            console.error('Erro ao parsear JSON:', error);
            result = defData; // Usa o defData como fallback em caso de erro no parse
        }
    }
    
    await setData(result);
    await setSite(site);
    await updateJsonIfDifferent(defData, result)
    // await updateParams(result, site);
    // await setOps(result);
    // await setHide(result);
    newAction('login', site, user.email || '');
}




    async function setHide(result){
      let temp = result
      for (const key in result){
        temp[key]['hide'] = null
      }
      console.log('Temp', temp)
    }
      
      async function updateParams(result, site){
        console.log('updateParams', result, site)
        let allData = await getAll(site)
        if (!allData){
          await populateDbBme(result)
        }else{
          await updateDbBME(result)
        }
        // await updateJsonIfDifferent(allData, result)
      }
    
      const getAll = async (site) => {
        let address = `${global.endPoint}/${site}/getAll`
        console.log('Address', address)
        try {
          const response = await fetch(`${global.endPoint}/${site}/getAll`);
          if (!response.ok) {
            throw new Error('Erro ao buscar dados da API');
          }
          
          let jsonData = await response.json();
          return jsonData
        } catch (error) {
          console.error('Erro ao buscar dados da API:', error);
        }
      }
    
    
      const populateDbBme = async (defData) => {
        try{
          const response  = await fetch(''+global.endPoint+'/populateDbBME', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({data: defData})
          })
          if (!response.ok) {
            throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          console.log('return', jsonData)
        }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
        }
      }
    
      const updateDbBME = async (defData) => {
        try{
          const response  = await fetch(''+global.endPoint+'/updateDbBME', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({data: defData})
          })
          if (!response.ok) {
            throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          console.log('return', jsonData)
        }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
        }
        
    
      }

      async function addNewElements(jsonA, jsonB) {
        // Itera sobre as chaves do jsonB
        for (const key in jsonB) {
          // Verifica se a chave do jsonB está ausente no jsonA
          if (!jsonA.hasOwnProperty(key)) {
            console.log('Var added', key)
            // Adiciona a chave e o valor de jsonB ao jsonA
            jsonA[key] = jsonB[key];
          }
        }
      }

      async function removeElements(jsonA, jsonB) {
        // Itera sobre as chaves do jsonB
        for (const key in jsonA) {
          // Verifica se a chave do jsonB está ausente no jsonA
          if (!jsonB.hasOwnProperty(key)) {
            console.log('Var Removed', key)
            // Adiciona a chave e o valor de jsonB ao jsonA
            delete jsonA[key]
          }
        }
      }

      
    
    async function updateJsonIfDifferent(json1, json2) {
      if (json1 && json2){
        for (const key in json1) {
          if (json1.hasOwnProperty(key) && json2.hasOwnProperty(key)) {
            if (json1[key].descricao !== json2[key].descricao) {
              json2[key].descricao = json1[key].descricao;
            }
            // if (json1[key].limiteMin !== json2[key].limiteMin) {
            //   json2[key].limiteMin = json1[key].limiteMin;
            // }
            // if (json1[key].limiteMax !== json2[key].limiteMax) {
            //   json2[key].limiteMax = json1[key].limiteMax;
            // }
            // if (json1[key].valorDefault !== json2[key].valorDefault) {
            //   json2[key].valorDefault = json1[key].valorDefault;
            // }
            // if (json1[key].alerta !== json2[key].alerta) {
            //   json2[key].alerta = json1[key].alerta;
            // }
            if (json1[key].unidade !== json2[key].unidade) {
              json2[key].unidade = json1[key].unidade;
            }
          }
        }
        setData(json2)
      }
    }

    // async function setOps(defData) {
    //   let temp = defData
    //   for (const key in defData) {
    //     temp[key]['op1'] = null
    //     temp[key]['op2'] = null 
    //   }
    //   console.log('temp', temp)
    // }

    const COLORS = UseColors()

    const columns = nSites > 4 ? 3 : nSites > 2 ? 2 : 1

    useEffect(() => {
      async function fetchData() {
        console.log(user)
        let sites = Object.keys(user.sites)
        let n = sites.length
        setNSites(n)
        console.log('Sites', sites, n);
        if (n === 1 && !site){
          await handleNomeClick(sites[0])
          console.log('Sites 1', sites, n);
          setSite(sites[0])
        }
      }

      fetchData()
      }, []);

    // Define o estilo do grid com base na quantidade de colunas
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: '10px', // Espaço entre os itens (opcional)
  };

    return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10vh', alignItems: 'center'}}>
                <img src="/logo512.png" alt="Logo" style={{height: '15vh', width: '15vh'}}/>
                <div style={{fontSize: '2vh', fontWeight: 'bold', margin: '3vh'}}>Selecione uma Unidade</div>
                <div style={gridStyle}>
            {user && Object.keys(user.sites).map((siteId, index) => (
                <button key={index} onClick={() => handleNomeClick(siteId)} 
                style={{cursor: 'pointer', color: 'white', padding: '2vh', backgroundColor: 'rgb(0, 122, 254)',
                    fontSize: '2.2vh', border: '0', borderRadius: '1vh', fontWeight: 'bold'
                 }}>
                    {user.sites[siteId].name}
                </button>
            ))}
            {user && user.sites.length=== 0 &&
            <div>Nenhuma unidade disponível para o usuário.</div> }
            
        </div>
            </div>
    );
};


export default ListaSites;

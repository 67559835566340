import React, { useContext, useState } from 'react';
import * as XLSX from 'xlsx';
import {dados} from './stdBoletim'
import { stdValue } from '../../../../Resources/setNumber';
import BoletinsHooks from '../../../../Hooks/boletinsHooks';
import GlobalStateContext from '../../../../components/GlobalStateContext';


const ExcelReader =  () => {
  const {site, data, setData} = useContext(GlobalStateContext)
  const [bltData, setBltData] = useState(null);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState('');
  const [date, setDate] = useState('');
  const {novoBoletim} = BoletinsHooks()
  

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError('Nenhum arquivo foi selecionado');
      return;
    }
    if (file) {
      setFileName(file.name);
    } else {
      setFileName('');  // Limpar o nome se nenhum arquivo for selecionado
    }
  
    const reader = new FileReader();
  
    reader.onload = async (e) => {  // Marcar como async
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
  
        if (workbook.SheetNames.length === 0) {
          setError('O arquivo Excel não contém planilhas');
          return;
        }
  
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,   // Lê os dados linha por linha
          defval: null, // Define valor padrão para células vazias
          raw: true    // Mantém o formato original dos valores
        });
  
        if (jsonData.length === 0) {
          setError('A planilha selecionada está vazia');
          return;
        }
  
        const removeEmptyCells = (data) => {
          let data2 = data.map(row => row.filter(cell => cell !== null && cell !== '' ));
          let data3 = data2.filter(item => item.length > 0)
          return data3
        };
  
        let jsonData2 = removeEmptyCells(jsonData)

        let temp = {}

        jsonData2[9].forEach((item, index) => {
          let newKey = item.replace(/\s+/g, '')
          temp[newKey] = jsonData2[12][index]
        })
  
        // console.log('tempData', jsonData)

        // let date = excelDateToJSDate(temp['VERWMERK'].slice(0,10)).toLocaleDateString()
        let dateString = temp['VERWMERK'].slice(0,10)
        const [day, month, year] = dateString.split(".").map(Number)
        const date = new Date(year, month - 1, day).toLocaleDateString()
        setDate(date)


        console.log('jsonData2', jsonData2, jsonData2[9], jsonData2[12], temp, temp['VERWMERK'].slice(0,10), date)


  
        // let orgArray = {}
        // dados.forEach(item => {
        //   orgArray = { ...orgArray, ...getVar2(item, jsonData2) }
        // })

        // console.log('orgArray', orgArray)

        // let tempData = transformData(orgArray)
        // // console.log('orgArray', tempData);
        
        // await novoBoletim(site, date, tempData)

        setBltData(temp);
        setError(null);
      } catch (error) {
        setError('Erro ao processar o arquivo Excel');
      }
    };
  
    reader.onerror = () => {
      setError('Erro ao ler o arquivo');
    };
  
    reader.readAsBinaryString(file);
  };

  function excelDateToJSDate(excelDate) {
    const startDate = new Date(1900, 0, 1); // 1º de janeiro de 1900
    const correctedDays = excelDate > 60 ? excelDate - 2 : excelDate - 1; // Corrigindo o bug do ano bissexto
    const date = new Date(startDate.getTime() + correctedDays * 24 * 60 * 60 * 1000);
    return date;
  }


  

 const secoes = [...new Set(dados.map(item => item.secao))]

 const handleClick = () => {
  document.getElementById('fileInput').click();
};


  return (
    <div style={{ padding: '2vh', textAlign: 'center', overflowY: 'scroll'}}>
      <div style={{marginBottom: '1vh', fontSize: '2vh', fontWeight: 'bold'}}>Importar Boletim</div>
      <input
      id="fileInput"
        type="file"
        accept=".xls,.xlsx"
        onChange={handleFileUpload}
        style={{display: 'none'}}
      />
       <button 
        onClick={handleClick}
        className='green-button'
      >
        Escolher arquivo
      </button>
      {fileName && (
        <span style={{ fontSize: '1.8vh', color: '#333', marginLeft: '2vh' }}>
          {fileName}
        </span>
      )}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{textAlign: 'left', marginLeft: '2.5vh', fontWeight: 'bold', fontSize: '1.8vh'}}>
            Data: {date}
          </div>
          {/* <div style={{textAlign: 'left', marginRight: '2.5vh', fontWeight: 'bold', fontSize: '1.6vh'}}>
            <button disabled={data ? false : true} className='green-button' style={{fontSize: '1.8vh'}} onClick={{}}>Salvar</button>
          </div> */}
        </div>
     
        <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh', marginBottom: '0.5vh', fontWeight: 'bold'}}>
            <div className='fullLine'>
                
                <div className='fullLineRow' style={{width: '28%'}}>Descrição</div>
                <div className='fullLineRow' style={{width: '18%'}}>Unidade</div>
                <div className='fullLineRow' style={{width: '16%'}}>Dia</div>
                <div className='fullLineRow' style={{width: '16%'}}>Mês</div>
                <div className='fullLineRow' style={{width: '16%'}}>Safra</div>
            </div>
      </div>
      <div className='body' style={{maxHeight: '60vh'}}>
      {bltData && secoes.map(secao => {
        return(
          <>
          <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.8vh', textAlign: 'left'}}>{secao}</div>
          <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderRadius: '0.5vh'}}>
          {dados
            .filter(item => item.secao === secao)
            .map(item => { 
              return (
                // <div>{data[key].bmeCod}</div>
                <div style={{padding: '0.3vh', borderBottom: '1px solid #f2f2f7'}}>
                  <div className='fullLine'>
                      <div className='fullLineRowBol' style={{width: '28%'}}>{item.name || ''}</div>
                      <div className='fullLineRowBol' style={{width: '18%'}}>{item.bltCod || ''}</div>
                      <div className='fullLineRowBol' style={{width: '16%'}}>{item.bmeCod || ''}</div>
                      <div className='fullLineRowBol' style={{width: '16%'}}>{item.psCod || ''}</div>
                      <div className='fullLineRowBol' style={{width: '16%'}}>{bltData[item.bltCod]}</div>
                  </div>
                </div> 
              )
            })}
            </div>
            </>
        )
      })}
      </div>
    </div>
  );
};

// {secao: 'MATÉRIA PRIMA', name: 'Pureza', bltCod: 'PURCNDGD', bmeCod: 'purezaCana', ipt: true},

export default ExcelReader;

function toFloat(value) {
  if (typeof value === 'number' && !isNaN(value)) {
    return value;
  }
  if (typeof value === 'string' && value.includes(',')) {
    return parseFloat(value.replace(',', '.'));
  }
  if (!isNaN(parseFloat(value))) {
    return parseFloat(value);
  }
  return NaN;
}

function getVar2(item, data) {
  let filtro = data.filter(row => row.includes(item.name))
  let array = filtro.length > 0 ? data.filter(row => row.includes(item.name))[item.n] : null
  let idx = array.lastIndexOf(item.name)
  if (!array){
    return {
      [item.name]: {
        unidade: null,
        dia: null,
        mes: null,
        safra: null,
        secao: item.secao,
        bmeCod: item.bmeCod ? item.bmeCod : '',
        psCod: item.psCod ? item.psCod : '',
        ipt: item.ipt ? item.ipt : false
      }
    }
  }
    return {
      [item.name]: {
        unidade: array[idx+1],
        dia: toFloat(array[idx+2]),
        mes: toFloat(array[idx+3]),
        safra: toFloat(array[idx+4]),
        secao: item.secao,
        bmeCod: item.bmeCod ? item.bmeCod : '',
        psCod: item.psCod ? item.psCod : '',
        ipt: item.ipt ? item.ipt : false
      }
    }
}

function findAllIndices(array, element) {
  const indices = [];
  array.forEach((item, index) => {
    if (item === element) {
      indices.push(index);
    }
  });
  return indices;
}

function isFloat(element) {
  // Substitui vírgulas por pontos para lidar com strings que usam vírgulas como separador decimal
  const parsed = parseFloat(typeof element === 'string' ? element.replace(',', '.') : element);

  // Retorna true se a conversão para float foi bem-sucedida e o valor resultante não é NaN
  return !isNaN(parsed) && parsed.toString() === element.toString();
}

function getVar(secao, name, col, bmeCod, psCod, ipt, array, n, row) {
  if (array.length === 4){
    return {
      [name]: {
        unidade: array[0],
        dia: toFloat(array[1]),
        mes: toFloat(array[2]),
        safra: toFloat(array[3]),
        secao: secao,
        bmeCod: bmeCod ? bmeCod : '',
        psCod: psCod ? psCod : '',
        ipt: ipt ? ipt : false
      }
    }
  }else{
    return {
      [name]: {
        unidade: n === 5 ? array[col+1] : array[1],
        dia: toFloat(array[col+(n === 5 ? 2 : 1)]),
        mes: toFloat(array[col+(n === 5 ? 3 : 2)]),
        safra: toFloat(array[col+(n === 5 ? 4 : 3)]),
        secao: secao,
        bmeCod: bmeCod ? bmeCod : '',
        psCod: psCod ? psCod : '',
        ipt: ipt ? ipt : false
      }
    }
  }
  
}

function transformData(data){
  let horasEfetivas = data['Cana Moída'].dia / data['Moagem Horária'].dia
  data['Cana Moída Moenda A'].dia = data['Cana Moída Moenda A'].dia/1000
  data['Cana Moída Moenda A'].mes = data['Cana Moída Moenda A'].mes/1000
  data['Cana Moída Moenda A'].safra = data['Cana Moída Moenda A'].safra/1000
  data['Cana Moída Moenda A'].unidade = 'ton'

  data['Moagem Horária Moenda A'].dia = data['Moagem Horária Moenda A'].dia/1000
  data['Moagem Horária Moenda A'].mes = data['Moagem Horária Moenda A'].mes/1000
  data['Moagem Horária Moenda A'].safra = data['Moagem Horária Moenda A'].safra/1000
  data['Moagem Horária Moenda A'].unidade = 'ton/h'

  data['Cana Moída Moenda B'].dia = data['Cana Moída Moenda B'].dia/1000
  data['Cana Moída Moenda B'].mes = data['Cana Moída Moenda B'].mes/1000
  data['Cana Moída Moenda B'].safra = data['Cana Moída Moenda B'].safra/1000
  data['Cana Moída Moenda B'].unidade = 'ton'

  data['Moagem Horária Moenda B'].dia = data['Moagem Horária Moenda B'].dia/1000
  data['Moagem Horária Moenda B'].mes = data['Moagem Horária Moenda B'].mes/1000
  data['Moagem Horária Moenda B'].safra = data['Moagem Horária Moenda B'].safra/1000
  data['Moagem Horária Moenda B'].unidade = 'ton/h'

  data['Cana Moída'].dia = data['Cana Moída'].dia/1000
  data['Cana Moída'].mes = data['Cana Moída'].mes/1000
  data['Cana Moída'].safra = data['Cana Moída'].safra/1000
  data['Cana Moída'].unidade = 'ton'

  data['Moagem Horária'].dia = data['Moagem Horária'].dia/1000
  data['Moagem Horária'].mes = data['Moagem Horária'].mes/1000
  data['Moagem Horária'].safra = data['Moagem Horária'].safra/1000
  data['Moagem Horária'].unidade = 'ton/h'

  data['Produção Anidro'].dia = data['Produção Anidro'].dia/1000
  data['Produção Anidro'].mes = data['Produção Anidro'].mes/1000
  data['Produção Anidro'].safra = data['Produção Anidro'].safra/1000
  data['Produção Anidro'].unidade = 'm³'

  data['Produção Hidratado'].dia = data['Produção Hidratado'].dia/1000
  data['Produção Hidratado'].mes = data['Produção Hidratado'].mes/1000
  data['Produção Hidratado'].safra = data['Produção Hidratado'].safra/1000
  data['Produção Hidratado'].unidade = 'm³'

  data['Produção Total Etanol'].dia = data['Produção Total Etanol'].dia/1000
  data['Produção Total Etanol'].mes = data['Produção Total Etanol'].mes/1000
  data['Produção Total Etanol'].safra = data['Produção Total Etanol'].safra/1000
  data['Produção Total Etanol'].unidade = 'm³'

  data['Produção Etanol 100%'].dia = data['Produção Etanol 100%'].dia/1000
  data['Produção Etanol 100%'].mes = data['Produção Etanol 100%'].mes/1000
  data['Produção Etanol 100%'].safra = data['Produção Etanol 100%'].safra/1000
  data['Produção Etanol 100%'].unidade = 'm³'

  data['Etanol no Processo'].dia = data['Etanol no Processo'].dia/1000
  data['Etanol no Processo'].mes = data['Etanol no Processo'].mes/1000
  data['Etanol no Processo'].safra = data['Etanol no Processo'].safra/1000
  data['Etanol no Processo'].unidade = 'm³'

  data['Mel Produzido'].dia = data['Mel Produzido'].dia/1000
  data['Mel Produzido'].mes = data['Mel Produzido'].mes/1000
  data['Mel Produzido'].safra = data['Mel Produzido'].safra/1000
  data['Mel Produzido'].unidade = 'ton'

  data['Mel Consumido'].dia = data['Mel Consumido'].dia/1000
  data['Mel Consumido'].mes = data['Mel Consumido'].mes/1000
  data['Mel Consumido'].safra = data['Mel Consumido'].safra/1000
  data['Mel Consumido'].unidade = 'ton'

  data['Mel Estoque Total'].dia = data['Mel Estoque Total'].dia/1000
  data['Mel Estoque Total'].mes = data['Mel Estoque Total'].mes/1000
  data['Mel Estoque Total'].safra = data['Mel Estoque Total'].safra/1000
  data['Mel Estoque Total'].unidade = 'ton'

  let melpEstoquedia = data['Mel Produzido'].dia - data['Mel Consumido'].dia
  // let melpEstoquedMes = data['Mel Produzido'].mes - data['Mel Consumido'].mes
  // let melpEstoqueSafra = data['Mel Produzido'].safra - data['Mel Consumido'].safra

  // data['Mel para Estoque'].dia = melpEstoquedia > 0 ? melpEstoquedia : 0
  // data['Mel para Estoque'].mes = melpEstoquedMes > 0 ? melpEstoquedMes : 0
  // data['Mel para Estoque'].safra = melpEstoqueSafra > 0 ? melpEstoqueSafra : 0
  // data['Mel para Estoque'].unidade = 'ton'

  // data['Mel para Produção'].dia = melpEstoquedia < 0 ? melpEstoquedia : 0
  // data['Mel para Produção'].mes = melpEstoquedMes < 0 ? melpEstoquedMes : 0
  // data['Mel para Produção'].safra = melpEstoqueSafra < 0 ? melpEstoqueSafra : 0
  // data['Mel para Produção'].unidade = 'ton'
if (melpEstoquedia > 0){
  data['Mel Produção p/ Estoque'].dia = melpEstoquedia/horasEfetivas
  data['Mel Estoque p/ Produção'].dia = 0
}else{
  data['Mel Estoque p/ Produção'].dia = melpEstoquedia/horasEfetivas
  data['Mel Produção p/ Estoque'].dia = 0
 }
data['Mel Produção p/ Estoque'].unidade = 't/h'
data['Mel Estoque p/ Produção'].unidade = 't/h'

data['Bagaço p/ Esoque'].dia = data['Bagaço p/ Esoque'].dia
data['Bagaço p/ Esoque'].unidade = 't'

data['Açúcar Prod. + ΔProcesso'].dia = data['Açúcar Prod. Total'].dia + data['Açúcar Processo'].dia
data['Açúcar Prod. + ΔProcesso'].unidade = 'Saco'

data['Prod. Total Etanol + ΔProcesso'].dia = data['Produção Total Etanol'].dia + data['Etanol no Processo'].dia
data['Prod. Total Etanol + ΔProcesso'].unidade = 'm³'

return data
  
}





import React, { useContext, useEffect } from 'react'
import GlobalStateContext from '../GlobalStateContext'
import { stdValue } from '../../Resources/setNumber';

function Flow({flow, x, y, align, valign, cods, a = ''}) {
    const {area, data, user, isPrinting} = useContext(GlobalStateContext)

//     const [updated, setUpdate] = useState(false)

  useEffect( () => {
    // console.log('Flow', flow, cods)
     cods.forEach(element => {
      if (!data[element]){
        console.log('Cod', element, data[element] ? 'OK' : 'Cod error')
      }
     });

  }, []);

  const vh = window.innerHeight
  const ideal = 727
  let nVH = ideal/vh

    const style = {
        position: 'absolute',
        height: `${isPrinting ? 1.5 * nVH : 1.5 }vh`, 
        width: `${isPrinting ? 15 * nVH : 15 }vh`,
        // border: '1px solid red',
        left: `${isPrinting ? x * nVH : x}vh`,
        top: `${isPrinting ? y * nVH : y}vh`,
        // border: '1px solid red',
        fontSize: `${isPrinting ? '11px' : '1.5vh'}`,
        fontFamily: 'sans-serif',
        letterSpacing: 'normal', // Ajuste o espaçamento entre letras
        wordSpacing: 'normal' // Ajuste o espaçamento entre palavras
      };

      function getAbrev(cod){
        if (area === 'balHidrico'){
            return ''
        }
        let abrev = cod.substring(0,3)
        switch (abrev){
            case 'pol': return 'Pol'
            case 'pur': return 'Pur'
            case 'fib': return 'Fib'
            case 'arC': return 'Ar'
            case 'art': if (cod.substring(0,8) !== 'artMassa'){return 'Art'}else{return ''}
            case 'bri': return 'Bx'
            default: return ''
        }
    }

  return (
    <div style={style}>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: `${valign}`}}>
        {flow !== '' ? 
        <div style={{margin: '0', textAlign: `${align}`, fontWeight: 'bold'}}>{flow}</div> :
        <></>}
          {data && cods.map((cod, index) => {
            return (
                <div key={''+cod+''+index} style={{margin: '0', textAlign: `${align}`, 
                color: `${checkAlert(data, cod, cods)}`
              }}>
                    {index === 0 && data[cod]?.valor && `${data[cod] ? stdValue(data[cod].valor) : cod} ${data[cod] && getUnit(data[cod],a, area)}${getAbrev(cod)}`}
                    {Math.abs(data[cods[0]]?.valor) > 0 && index > 0 && data[cod]?.valor && `${data[cod] ? stdValue(data[cod]?.valor) : cod} ${area !== 'balHidrico' ? data[cod] ? data[cod].unidade : cod : 'ºC'}${getAbrev(cod)}`}
                   
                    </div>
            )
        })}
        </div>
      </div>
  )
}

export default Flow

function getUnit(item, a, area){
  if (area !== 'balHidrico' && a === ''){
    return item.unidade
  }else{
    return ''
  }
}

function checkAlert(data, cod, cods){
  
  if (data[cod] && data[cod].valor){
    // console.log(' Okkkk GetAlert', cod, cods, data)
      return `${(((data[cod] && data[cod].valor < data[cod].limiteMin) && (data[cod].limiteMin !== null)) || ((data[cod].valor > data[cod].limiteMax) && (data[cod].limiteMax !== null))) ? 'red' : 'black'}`
  }else{
    // console.log('GetAlert', cod, cods, data)
    return 'black'
  }
}

